$w: 1680px;
$speed: 300ms;
$mobile: 1000px;
@function size($px) {
    @return calc(#{$px} / 1680 * 100vw);
}
@function sizem($px) {
    @return calc(#{$px} / 750 * 100vw);
}

@font-face {
  font-family: 'Frederik';
  src: local('Frederik Regular'), local('Frederik-Regular'),
      url('/assets/fonts/Frederik-Regular.woff2') format('woff2'),
      url('/assets/fonts/Frederik-Regular.woff') format('woff'),
      url('/assets/fonts/Frederik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'adigiana_2regular';
  src: url('/assets/fonts/3360-font-webfont.eot');
  src: url('/assets/fonts/3360-font-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/3360-font-webfont.woff2') format('woff2'),
       url('/assets/fonts/3360-font-webfont.woff') format('woff'),
       url('/assets/fonts/3360-font-webfont.ttf') format('truetype'),
       url('/assets/fonts/3360-font-webfont.svg#adigiana_2regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@keyframes body {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

img{
  display: block;
}

body{
  font-family: 'Frederik';
  opacity: 0;
  overflow: hidden;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  animation: body 1.5s 1 forwards;
}

body.load{
  opacity: 1;
}

body .container *{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

body.active,
body.active *{
  //cursor: none;
}

.container{
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  cursor: none;
  z-index: 1;
}
.container *{
  cursor: none;
}

.draggable{
  left: -1300px;
  top: 0;
  overflow: hidden;

  @media (max-width: $mobile) {
    left: sizem(-1910);
    top: 0;
  }
}

.bg{
  position: absolute;
}

.draggable-bg{
  width: 4340px;

  @media (max-width: $w) {
    width: size(4340);
  }
  @media (max-width: $mobile) {
    width: sizem(5230);
  }
}
.mob{
  display: none !important;

  @media (max-width: $mobile) {
    display: block !important;
  }
}
.desktop{
  @media (max-width: $mobile) {
    display: none !important;
  }
}

img{
  display: block;
}

.snow{
  width: auto !important;
  height: auto !important;
  border-radius: 50%;
  pointer-events: none;
}

.chel{
  position: absolute;
  left: 44%;
  top: 20%;
  animation: 1s anim infinite;
  transform-origin: 50% 100%;
}

.cursor{
  opacity: 0;
  transition: 300ms opacity;
  position: absolute;
  z-index: 1400;
  margin-left: -60px;
  margin-top: -75px;
  pointer-events: none;

  width: 150px;
  height: 150px;

  @media (max-width: $w) {
    width: size(150);
    height: size(150);
    margin-left: size(-60);
    margin-top: size(-75);
  }
  &.hide{
    display: none;
  }
  @media (max-width: $mobile) {
    display: none;
  }
}

.cursor img{
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.cursor img:nth-child(2),
.cursor img:nth-child(3){
  display: none;
}

.cursor.mousedown img:nth-child(1){
  display: none;
}

.cursor.mousedown img:nth-child(2){
  display: block;
}

.cursor.onbuttons img:nth-child(1),
.cursor.onbuttons img:nth-child(2){
  display: none !important;
}

.cursor.onbuttons img:nth-child(3){
  display: block !important;
}

body.active .cursor{
  opacity: 1;
}

.popup{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 11;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
}

body.active .popup{
  opacity: 0;
  visibility: hidden;
}

.popup__in{
  position: relative;
  top: 50px;
  transition: 300ms transform;
}

body.active .popup__in{
  transform: scale(0.9);
}

.popup__view-img{
  position: relative;
  z-index: 2;

  width: 874px;
  @media (max-width: $w) {
    width: size(874);
  }
  @media (max-width: $mobile) {
    width: sizem(799);
    left: sizem(-30);
  }
}

.popup__view{
  position: absolute;
  left: 15%;
  top: 18%;
  width: 65%;
  height: 49%;
  z-index: 1;
  overflow: hidden;
}

@keyframes panim3 {
  0%{
    transform: translateX(0);
  }
  24.9%{
    transform: translateX(0);
  }
  50%{
    transform: translateX(-250px);
  }
  75%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(0);
  }
}

.popup__view-in{
  position: absolute;
  left: 0;
  top: 0;
  animation: 4s panim3 infinite;

  width: 1049px;
  @media (max-width: $w) {
    width: size(1049);
  }
  @media (max-width: $mobile) {
    width: sizem(1049);
  }

  img{
    width: 100%;
  }
}

.popup__closelayer{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.popup__close{
  position: absolute;
  right: 40px;
  top: 40px;
  transition: 300ms transform;
  cursor: pointer;
  z-index: 10;
  padding: 60px;

  width: 57px;
  @media (max-width: $w) {
    width: size(57);
    right: size(-20);
    top: size(-20);
  }
  @media (max-width: $mobile) {
    width: sizem(57);
    right: sizem(-73);
    top: sizem(-65);
  }

  img{
    width: 100%;
    display: block;
  }
}

.popup__close:hover{
  transform: scale(0.8);
}

@keyframes panim1 {
  0%{
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
  24.9%{
    opacity: 1;
    transform: translateX(150px) translateY(-200px);
  }
  25%{
    opacity: 0;
    transform: translateX(150px) translateY(-200px);
  }
  50%{
    opacity: 0;
    transform: translateX(-100px) translateY(-200px);
  }
  74.9%{
    opacity: 0;
    transform: translateX(150px) translateY(-200px);
  }
  75.9%{
    opacity: 1;
    transform: translateX(150px) translateY(-200px);
  }
  100%{
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}
@keyframes panim1_mob {
  0%{
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
  24.9%{
    opacity: 1;
    transform: translateX(75px) translateY(-100px);
  }
  25%{
    opacity: 0;
    transform: translateX(75px) translateY(-100px);
  }
  50%{
    opacity: 0;
    transform: translateX(-50px) translateY(-100px);
  }
  74.9%{
    opacity: 0;
    transform: translateX(75px) translateY(-100px);
  }
  75.9%{
    opacity: 1;
    transform: translateX(75px) translateY(-100px);
  }
  100%{
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

@keyframes panim2 {
  0%{
    opacity: 0;
    transform: translateX(0) translateY(0);
  }
  24.9%{
    opacity: 0;
    transform: translateX(150px) translateY(-200px);
  }
  25%{
    opacity: 1;
    transform: translateX(150px) translateY(-200px);
  }
  50%{
    opacity: 1;
    transform: translateX(-100px) translateY(-200px);
  }
  74.9%{
    opacity: 1;
    transform: translateX(150px) translateY(-200px);
  }
  75.9%{
    opacity: 0;
    transform: translateX(150px) translateY(-200px);
  }
  100%{
    opacity: 0;
    transform: translateX(0) translateY(0);
  }
}
@keyframes panim2_mob {
  0%{
    opacity: 0;
    transform: translateX(0) translateY(0);
  }
  24.9%{
    opacity: 0;
    transform: translateX(75px) translateY(-100px);
  }
  25%{
    opacity: 1;
    transform: translateX(75px) translateY(-100px);
  }
  50%{
    opacity: 1;
    transform: translateX(-50px) translateY(-100px);
  }
  74.9%{
    opacity: 1;
    transform: translateX(75px) translateY(-100px);
  }
  75.9%{
    opacity: 0;
    transform: translateX(75px) translateY(-100px);
  }
  100%{
    opacity: 0;
    transform: translateX(0) translateY(0);
  }
}

.popup__cursor{
  position: absolute;
  z-index: 3;
  left: 50%;
  bottom: 520px;
  margin-left: -140px;
  @media (max-width: $w) {
    bottom: size(520);
    margin-left: size(-140);
  }
  @media (max-width: $mobile) {
    bottom: sizem(380);
    margin-left: sizem(-140);
  }
}

.popup__cursor img{
  position: absolute;
  left: 0;
  top: 0;

  width: 200px;
  @media (max-width: $w) {
    width: size(200);
  }
  @media (max-width: $mobile) {
    width: sizem(200);
  }
}

.popup__cursor img:nth-child(1){
  animation: 4s panim1 infinite;
  @media (max-width: $mobile) {
    animation: 4s panim1_mob infinite;
  }
}

.popup__cursor img:nth-child(2){
  opacity: 0;
  animation: 4s panim2 infinite;
  @media (max-width: $mobile) {
    animation: 4s panim2_mob infinite;
  }
}

.popup__gif{
  position: absolute;
  z-index: 3;
  right: 12px;
  bottom: 340px;
  width: 348px;
  @media (max-width: $w) {
    right: size(12);
    bottom: size(340);
    width: size(348);
  }
  @media (max-width: $mobile) {
    right: sizem(-10);
    bottom: sizem(200);
    width: sizem(318);
  }
}

.elements{
  position: absolute;
}

@keyframes anim1 {
  0%{
    transform: scale(1, 1);
  }
  50%{
    transform: scale(0.95, 1.05);
  }
  100%{
    transform: scale(1, 1);
  }
}

@keyframes anim2 {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes anim3 {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.03);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes anim4 {
  0%{
    opacity: 0;
    transform: translateY(100%) scale(0.5);
  }
  80%{
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  100%{
    opacity: 0;
  }
}

@keyframes anim5 {
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(7deg);
  }
  100%{
    transform: rotate(0);
  }
}

@keyframes anim6 {
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-7deg);
  }
  100%{
    transform: rotate(0);
  }
}

@keyframes anim7 {
  0%{
    transform: translateY(0) translateX(0);
  }
  25%{
    transform: translateY(-3%) translateX(3%);
  }
  50%{
    transform: translateY(3%) translateX(-3%);
  }
  75%{
    transform: translateY(-3%) translateX(2%);
  }
  100%{
    transform: translateY(0) translateX(0);
  }
}

@keyframes anim8 {
  0%{
    transform: translateY(0) translateX(0);
  }
  25%{
    transform: translateY(3%) translateX(-3%);
  }
  50%{
    transform: translateY(-3%) translateX(3%);
  }
  75%{
    transform: translateY(3%) translateX(-2%);
  }
  100%{
    transform: translateY(0) translateX(0);
  }
}

@keyframes anim9 {
  0%{
    transform: scale(1, 1);
  }
  50%{
    transform: scale(0.98, 1.02);
  }
  100%{
    transform: scale(1, 1);
  }
}

@keyframes anim10 {
  0%{
    opacity: 0;
    transform: scale(0.5);
  }
  50%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 0;
    transform: scale(0.5);
  }
}

@keyframes anim11 {
  0%{
    transform: rotate(-5deg);
  }
  50%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(-5deg);
  }
}


@keyframes anim12 {
  0%{
    transform: rotate(-2deg);
  }
  5%{
    transform: rotate(1deg);
  }
  10%{
    transform: rotate(-1deg);
  }
  15%{
    transform: rotate(2deg);
  }
  20%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(0deg);
  }
}

@keyframes anim13 {
  0%{
    transform: scale(1, 1) rotate(-2deg);
  }
  25%{
    transform: scale(0.95, 1.05) rotate(0deg);
  }
  50%{
    transform: scale(1, 1) rotate(2deg);
  }
  75%{
    transform: scale(0.95, 1.05) rotate(0deg);
  }
  100%{
    transform: scale(1, 1) rotate(-2deg);
  }
}

@keyframes anim14 {
  0%{
    transform: rotate(-2deg);
  }
  50%{
    transform: rotate(2deg);
  }
  100%{
    transform: rotate(-2deg);
  }
}

@keyframes anim15 {
  0%{
    transform: scale(1,1) translateX(0) translateY(0);
  }
  5%{
    transform: scale(1.05,.95) translateX(0) translateY(0);
  }
  10%{
    transform: scale(.95,1.05) translateX(20px) translateY(-30px);
  }
  17%{
    transform: scale(1,1) translateX(0) translateY(0);
  }
  100%{
    transform: scale(1,1) translateX(0) translateY(0);
  }
}
@keyframes anim16 {
  0%{
    transform: translateY(0%);
  }
  50%{
    transform: translateY(15%);
  }
  100%{
    transform: translateY(0%);
  }
}
@keyframes anim17 {
  0%{
    transform: translateY(0%);
  }
  50%{
    transform: translateY(-15%);
  }
  100%{
    transform: translateY(0%);
  }
}
@keyframes anim18 {
  0%{
    bottom: -1%;
  }
  100%{
    bottom: 100%;
  }
}

.buttons{
  transition: 300ms transform;
  cursor: pointer;
  max-width: 100%;
}

.buttons + .elements{
  transition: 300ms transform;
  transform-origin: 0 0;
}

.buttons-in:hover + .buttons{
  transform: scale(0.9);
}

.buttons-in:hover + .buttons + .elements{
  transform: scale(0.9) translateX(-10%);
}

.element1{
  left: 44.1%;
  top: 10.5%;
  animation: 1s anim1 infinite;
  transform-origin: 50% 100%;

  width: 197px;
  @media (max-width: $w) {
    width: size(197);
  }
  @media (max-width: $mobile) {
    width: sizem(197);
  }
}

.element2{
  left: 48.1%;
  top: 7.3%;
  animation: 1s anim2 infinite;
  transform-origin: 0 100%;

  width: 197px;
  @media (max-width: $w) {
    width: size(197);
  }
  @media (max-width: $mobile) {
    width: sizem(197);
  }
}

.element3{
  left: 41%;
  top: 1%;
  animation: 2s anim3 infinite;

  width: 723px;
  @media (max-width: $w) {
    width: size(723);
  }
  @media (max-width: $mobile) {
    width: sizem(601);
    left: 37.7%;
    top: 7.8%;
  }
}

.element4{
  left: 49.5%;
  top: 11.4%;
  animation: 1s anim4 infinite;

  width: 46px;
  @media (max-width: $w) {
    width: size(46);
  }
  @media (max-width: $mobile) {
    width: sizem(46);
  }
}

.element5{
  left: 50.2%;
  top: 12.2%;
  animation: 1s anim4 infinite;
  animation-delay: 300ms;

  width: 72px;
  @media (max-width: $w) {
    width: size(72);
  }
  @media (max-width: $mobile) {
    width: sizem(72);
  }
}

.element6{
  left: 11%;
  top: 20%;
  animation: 2s anim5 infinite;
  transform-origin: 50% 0;

  width: 186px;
  @media (max-width: $w) {
    width: size(186);
  }
  @media (max-width: $mobile) {
    width: sizem(186);
    left: 17%;
    top: 25.3%;
  }
}

.element7{
  left: 49.2%;
  top: 12%;
  animation: 2s anim13 infinite;
  transform-origin: 50% 100%;

  width: 458px;
  @media (max-width: $w) {
    width: size(458);
  }
  @media (max-width: $mobile) {
    width: sizem(458);
    left: 43.7%;
    top: 23%;
  }
}

.element8{
  left: 54.2%;
  top: 21.6%;
  animation: 2s anim3 infinite;
  z-index: 3;

  width: 245px;
  @media (max-width: $w) {
    width: size(245);
  }
  @media (max-width: $mobile) {
    width: sizem(245);
    left: 48%;
    top: 36.6%;
  }
}

.element9{
  left: 57.2%;
  top: 24.2%;
  animation: 1s anim6 infinite;
  z-index: 3;

  width: 222px;
  @media (max-width: $w) {
    width: size(222);
  }
  @media (max-width: $mobile) {
    width: sizem(404);
    left: 50.3%;
    top: 38.2%;
  }
}

.element10{
  left: 60%;
  top: 28%;
  animation: 500ms anim7 infinite;

  width: 30px;
  @media (max-width: $w) {
    width: size(30);
  }
  @media (max-width: $mobile) {
    width: sizem(30);
    left: 52.4%;
    top: 42.3%;
  }
}

.element11{
  left: 58.6%;
  top: 28.4%;
  animation: 450ms anim8 infinite;

  width: 44px;
  @media (max-width: $w) {
    width: size(44);
  }
  @media (max-width: $mobile) {
    width: sizem(44);
    left: 51.3%;
    top: 42.5%;
  }
}

.element12{
  left: 59.7%;
  top: 29.1%;

  width: 22px;
  @media (max-width: $w) {
    width: size(22);
  }
  @media (max-width: $mobile) {
    width: sizem(22);
    left: 52.1%;
    top: 43.2%;
  }
}

.element13{
  left: 70%;
  top: 22.1%;
  z-index: 3;

  width: 175px;
  @media (max-width: $w) {
    width: size(175);
  }
  @media (max-width: $mobile) {
    width: sizem(175);
    left: 61.2%;
    top: 37%;
  }
}

.element14{
  left: 59.7%;
  top: 18.8%;
  z-index: 0;
  animation: 2s anim9 infinite;
  transform-origin: 50% 100%;

  width: 637px;
  @media (max-width: $w) {
    width: size(637);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    left: 52.5%;
    top: 34.7%;
  }
}

.element15{
  left: 76%;
  top: 33.5%;
  animation: 2s anim3 infinite;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(304);
    left: 66.3%;
    top: 48.2%;
  }
}

.element16{
  left: 78%;
  top: 36.6%;
  animation: 1s anim6 infinite;

  width: 200px;
  @media (max-width: $w) {
    width: size(200);
  }
  @media (max-width: $mobile) {
    width: sizem(394);
    left: 65.5%;
    top: 50.7%;
  }
}

.element17{
  left: 75.4%;
  top: 31.7%;
  animation: 1s anim10 infinite;
  transform-origin: 0 70%;

  width: 49px;
  @media (max-width: $w) {
    width: size(49);
  }
  @media (max-width: $mobile) {
    width: sizem(49);
    left: 65.8%;
    top: 46.65%;
  }
}

.element18{
  left: 77.4%;
  top: 39.2%;
  animation: 1.2s anim10 infinite;
  transform-origin: 100% 20%;

  width: 236px;
  @media (max-width: $w) {
    width: size(236);
  }
  @media (max-width: $mobile) {
    width: sizem(236);
    top: 52.7%;
    left: 68.4%;
  }
}

.element19{
  left: 86.4%;
  top: 37.5%;
  animation: 3s anim11 infinite ease;
  transform-origin: 50% 0;

  width: 100px;
  @media (max-width: $w) {
    width: size(100);
  }
  @media (max-width: $mobile) {
    width: sizem(100);
    top: 51.9%;
    left: 75.9%;
  }
}

.element20{
  left: 56%;
  top: 39.1%;
  z-index: 3;
  animation: 2s anim3 infinite;

  width: 326px;
  @media (max-width: $w) {
    width: size(326);
  }
  @media (max-width: $mobile) {
    width: sizem(326);
    left: 47.5%;
    top: 52.2%;
  }
}

.element21{
  left: 55.2%;
  top: 42.3%;
  z-index: 3;
  animation: 1s anim6 infinite;

  width: 220px;
  @media (max-width: $w) {
    width: size(220);
  }
  @media (max-width: $mobile) {
    width: sizem(458);
    left: 46.7%;
    top: 54.7%;
  }
}

.element22{
  left: 50.2%;
  top: 35.8%;
  animation: 1s anim1 infinite;
  transform-origin: 50% 100%;

  width: 279px;
  @media (max-width: $w) {
    width: size(279);
  }
  @media (max-width: $mobile) {
    width: sizem(279);
    left: 42.7%;
    top: 49.7%;
  }
}

.element23{
  left: 39.2%;
  top: 23.3%;
  animation: 2s anim3 infinite;

  width: 323px;
  @media (max-width: $w) {
    width: size(323);
  }
  @media (max-width: $mobile) {
    width: sizem(323);
    top: 30.3%;
  }
}

.element24{
  left: 41%;
  top: 26.7%;
  z-index: 4;
  animation: 1.2s anim6 infinite;

  width: 219px;
  @media (max-width: $w) {
    width: size(219);
  }
  @media (max-width: $mobile) {
    width: sizem(383);
    left: 39.2%;
    top: 33%;
  }
}

.element25{
  left: 33.2%;
  top: 23.3%;
  z-index: 3;

  width: 258px;
  @media (max-width: $w) {
    width: size(258);
  }
  @media (max-width: $mobile) {
    width: sizem(258);
    left: 34.4%;
    top: 29.9%;
  }
}

.element26{
  left: 30.8%;
  top: 24.7%;
  animation: 2s anim12 infinite;

  width: 480px;
  @media (max-width: $w) {
    width: size(480);
  }
  @media (max-width: $mobile) {
    width: sizem(480);
    left: 32%;
    top: 31.3%;
  }
}
.element66{
  left: 66.77%;
  top: 4%;
  animation: 2s anim3 infinite;
  z-index: 3;

  width: 249px;
  @media (max-width: $w) {
    width: size(249);
  }
  @media (max-width: $mobile) {
    width: sizem(249);
    left: 65.65%;
    top: 18.3%;
  }
}

.element27{
  left: 67.74%;
  top: 10%;
  z-index: 3;
  animation: 1.2s anim6 infinite;

  width: 197px;
  @media (max-width: $w) {
    width: size(197);
  }
  @media (max-width: $mobile) {
    width: sizem(400);
    left: 65.6%;
    top: 23.9%;
  }
}

.element28{
  left: 67.74%;
  top: 7.1%;
  animation: 2s anim12 infinite;
  transform-origin: 50% 100%;

  width: 115px;
  @media (max-width: $w) {
    width: size(115);
  }
  @media (max-width: $mobile) {
    width: sizem(126);
    left: 66.2%;
    top: 20.8%;
  }
}

.buttons-in{
  position: absolute;
  z-index: 3;
  opacity: 0.5;
}

.button-in1{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-10deg);
}

.button-in2{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-10deg);
}

.button-in3{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(13deg);
}

.button-in4{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-10deg);
}

.button-in5{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-10deg);
}

.element29{
  left: 44.8%;
  top: 21.4%;

  width: 399px;
  @media (max-width: $w) {
    width: size(399);
  }
  @media (max-width: $mobile) {
    width: sizem(399);
  }
}

.element30{
  right: 2%;
  top: 23%;

  width: 53px;
  @media (max-width: $w) {
    width: size(53);
  }
  @media (max-width: $mobile) {
    width: sizem(53);
  }
}

.element31{
  left: 57.3%;
  top: 31%;

  width: 145px;
  @media (max-width: $w) {
    width: size(145);
  }
  @media (max-width: $mobile) {
    width: sizem(201);
    left: 49.3%;
    top: 44.9%;
  }
}

.element32{
  right: 2%;
  top: 23%;

  width: 53px;
  @media (max-width: $w) {
    width: size(53);
  }
  @media (max-width: $mobile) {
    width: sizem(53);
  }
}

.element33{
  left: 34%;
  top: 59%;
  animation: 2s anim3 infinite;

  width: 457px;
  @media (max-width: $w) {
    width: size(457);
  }
  @media (max-width: $mobile) {
    width: sizem(502);
    left: 31.4%;
    top: 69.5%;
  }
}

.element34{
  left: 37%;
  top: 61.7%;
  animation: 1s anim6 infinite;

  width: 224px;
  @media (max-width: $w) {
    width: size(224);
  }
  @media (max-width: $mobile) {
    width: sizem(498);
    left: 33.4%;
    top: 72%;
  }
}

.button-in6{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
}

.element35{
  left: 7%;
  top: 61%;
  animation: 2s anim3 infinite;

  width: 378px;
  @media (max-width: $w) {
    width: size(378);
  }
  @media (max-width: $mobile) {
    width: sizem(378);
    left: 7.3%;
    top: 71.8%;
  }
}

.element36{
  left: 10%;
  top: 65.2%;
  animation: 1s anim6 infinite;

  width: 224px;
  @media (max-width: $w) {
    width: size(224);
  }
  @media (max-width: $mobile) {
    width: sizem(450);
    left: 6.4%;
    top: 75.2%;
    z-index: 5;
  }
}

.button-in7{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
}

.element37{
  left: 50.8%;
  top: 65.9%;
  z-index: 3;
  animation: 1s anim6 infinite;

  width: 222px;
  @media (max-width: $w) {
    width: size(222);
  }
  @media (max-width: $mobile) {
    width: sizem(394);
    left: 51%;
    top: 70.2%;
  }
}

.button-in8{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-12deg);
}

.element38{
  left: 49.8%;
  top: 68%;
  z-index: 3;
  animation: 2s anim3 infinite;

  width: 357px;
  @media (max-width: $w) {
    width: size(357);
  }
  @media (max-width: $mobile) {
    width: sizem(357);
    left: 53.2%;
    top: 72.1%;
  }
}

.element39{
  z-index: 1;
  left: 52.2%;
  top: 59.2%;
  animation: 1s anim3 infinite;

  width: 853px;
  @media (max-width: $w) {
    width: size(853);
  }
  @media (max-width: $mobile) {
    width: sizem(898);
    left: 54.8%;
    top: 65.25%;
  }
}

.element40{
  z-index: 2;
  left: 52.2%;
  top: 55.2%;

  width: 476px;
  @media (max-width: $w) {
    width: size(476);
  }
  @media (max-width: $mobile) {
    width: sizem(476);
    left: 55.2%;
    top: 62.2%;
  }
}

.element43{
  z-index: 3;
  left: 64.4%;
  top: 60.87%;

  width: 98px;
  @media (max-width: $w) {
    width: size(98);
  }
  @media (max-width: $mobile) {
    width: sizem(98);
  }
}

.element41{
  left: 69.8%;
  top: 71.5%;
  animation: 2s anim3 infinite;

  width: 324px;
  @media (max-width: $w) {
    width: size(324);
  }
  @media (max-width: $mobile) {
    width: sizem(324);
    left: 72.6%;
    top: 80.5%;
  }
}

.element42{
  left: 72.7%;
  top: 73.7%;
  animation: 1s anim6 infinite;

  width: 262px;
  @media (max-width: $w) {
    width: size(262);
  }
  @media (max-width: $mobile) {
    width: sizem(467);
    left: 71.7%;
    top: 82.3%;
  }
}

.element45{
  left: 30%;
  top: 81.4%;
  z-index: 2;
  animation: 2s anim3 infinite;

  width: 336px;
  @media (max-width: $w) {
    width: size(336);
  }
  @media (max-width: $mobile) {
    width: sizem(336);
    left: 33.1%;
    top: 84.8%;
  }
}

.element47{
  left: 35%;
  top: 76%;

  width: 1098px;
  @media (max-width: $w) {
    width: size(1098);
  }
  @media (max-width: $mobile) {
    width: sizem(1098);
  }
}

.element46{
  left: 32%;
  top: 84.4%;
  z-index: 2;
  animation: 1s anim6 infinite;

  width: 312px;
  @media (max-width: $w) {
    width: size(312);
  }
  @media (max-width: $mobile) {
    width: sizem(624);
    left: 30%;
    top: 87%;
  }
}

.button-in46{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-13deg);
}

.button-in9{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-10deg);
}

.close-popup{
  transition: 300ms opacity, 300ms transform;
  opacity: 0;
  transform: scale(0.8);
}

.close-popup.active{
  opacity: 1;
  transform: scale(1);
}

.element44{
  left: 81.5%;
  top: 31.4%;
  z-index: 3;

  width: 399px;
  @media (max-width: $w) {
    width: size(399);
  }
  @media (max-width: $mobile) {
    width: sizem(399);
  }
}

.popup-film{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.popup-film.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.popup-film__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
}

.popup-film.active .popup-film__in{
  transform: scale(1);
}

.popup-film__in .popup__close{
  right: 54px;
  top: -30px;
  padding: 30px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(54);
    top: size(-30);
    padding: size(30);
  }

  img{
    width: 100%;
  }
}

.popup-film__bg{
  width: 983px;

  @media (max-width: $w) {
    width: size(983);
  }
  @media (max-width: $mobile) {
    width: sizem(222);
    position: absolute;
    right: 0;
    top: sizem(255);
  }
}

.popup-film-title{
  font-family: 'adigiana_2regular';
  position: absolute;
  z-index: 2;
  color: #fe0000;
  width: 93%;
  text-align: center;
  left: 0;
  transform: rotate(-5deg);
  top: 71px;
  font-size: 136px;
  margin-bottom: 0;

  @media (max-width: $w) {
    top: size(71);
    font-size: size(136);
  }
  @media (max-width: $mobile) {
    top: sizem(43);
    font-size: sizem(180);
  }
}
.popup-film-start__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #fe0000;
  width: 100%;
  text-align: center;
  left: 0;
  transform: rotate(-5deg);
  top: -22px;
  font-size: 136px;
  line-height: 72px;
  margin-bottom: 0;

  @media (max-width: $w) {
    top: size(-22);
    font-size: size(136);
    line-height: size(72);
  }
  @media (max-width: $mobile) {
    top: sizem(50);
    font-size: sizem(136);
    line-height: sizem(72);
    margin-bottom: sizem(120);
  }
  span{
    display: block;
    &:first-child{
      font-size: 72px;
      margin-left: -55px;
      @media (max-width: $w) {
        font-size: size(72);
        line-height: size(72);
        margin-left: size(-55);
      }
      @media (max-width: $mobile) {
        font-size: sizem(72);
        line-height: sizem(72);
        margin-left: sizem(-55);
      }
    }
    &:nth-child(2){
      margin-top: -20px;
      margin-left: -85px;
      @media (max-width: $w) {
        margin-top: size(-20);
        margin-left: size(-85);
      }
      @media (max-width: $mobile) {
        margin-top: sizem(-20);
        margin-left: sizem(-85);
      }
    }
    &:nth-child(3){
      margin-left: 20px;
      @media (max-width: $w) {
        margin-left: size(20);
      }
    }
  }
}
.popup-film-start__text{
  font-family: 'adigiana_2regular';
  font-size: 30px;
  line-height: 1;
  margin-top: 120px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;

  @media (max-width: $w) {
    font-size: size(30);
    margin-top: size(120);
    margin-bottom: size(50);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    line-height: 0.9;
    margin-top: sizem(65);
  }
  & > div{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    @media (max-width: $w) {
      margin-top: size(30);
    }
    @media (max-width: $mobile) {
      margin-top: sizem(30);
    }

    &:first-child{
      text-align: center;
      padding-right: 40%;
      div{
        transform: rotate(5deg);
      }
    }
    &:nth-child(1){
      text-align: right;
      padding-right: 42%;
      font-size: 1.2em;
      div{
        transform: rotate(5deg);
      }
      @media (max-width: $mobile) {
        text-align: center;
        padding-right: 27%;
        padding-left: 11%;
        font-size: 1em;
      }
    }
    &:nth-child(2){
      text-align: left;
      justify-content: flex-start;
      padding-left: 46%;
      margin-top: 130px;
      @media (max-width: $w) {
        margin-top: size(130);
      }
      div{
        transform: rotate(-5deg);
      }
      @media (max-width: $mobile) {
        margin-top: sizem(50);
        padding-left: 0%;
        text-align: right;
        width: 67%;
        div{
          transform: rotate(5deg);
        }
        br{
          display: none;
        }
      }
    }
  }
}
.popup-film__imgbottom{
  position: absolute;
  left: 0;
  top: sizem(670);
  width: sizem(301);
  z-index: 0;
  img{
    display: block;
    width: 100%;
  }
}
.popup-state-start,.popup-state-list{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 300ms opacity, 300ms visibility;
  @media (max-width: $mobile) {
    z-index: 2;
    position: relative;
    display: none;
    min-height: 100%;
    height: auto;
  }
}
.popup-state-list{
  @media (max-width: $mobile) {
    height: 137vw;
  }
}
.popup-state-start.active,.popup-state-list.active{
  opacity: 1;
  visibility: visible;
  @media (max-width: $mobile) {
    display: block;
  }
}

.slick-slider.popup-film__slider{
  position: absolute;
  width: 100%;
  left: 0;
  top: 210px;
  z-index: 1;

  @media (max-width: $w) {
    top: size(210);
  }
  @media (max-width: $mobile) {
    top: sizem(260);
  }
}

.popup-film__slider .slick-track{
  display: flex;
}

.popup-film__slider .slick-arrow{
  font-size: 0;
  border: none;
  cursor: none;
  position: absolute;
  z-index: 3;
  top: 46%;
  margin-top: -100px;

  @media (max-width: $w) {
    margin-top: size(-100);
  }
}

.popup-film__slider .slick-prev{
  width: 181px;
  height: 192px;
  background: url('/assets/img/left.png') center bottom no-repeat;
  background-size: contain !important;
  left: -4%;

  @media (max-width: $w) {
    width: size(181);
    height: size(192);
  }
  @media (max-width: $mobile) {
    width: sizem(177);
    height: sizem(193);
    left: -2%;
    top: sizem(280);
  }
}

.popup-film__slider .slick-next{
  width: 168px;
  height: 174px;
  background: url('/assets/img/right.png') center bottom no-repeat;
  background-size: contain !important;
  right: -5%;

  @media (max-width: $w) {
    width: size(168);
    height: size(174);
  }
  @media (max-width: $mobile) {
    width: sizem(164);
    height: sizem(182);
    right: -1%;
    top: sizem(280);
  }
}

.popup-film__slide1{
  width: 374px;
  @media (max-width: $w) {
    width: size(374);
  }
}
.popup-film__cover{
  width: 374px;
  height: 464px;
  margin: 0 auto 5px auto;
  position: relative;
  @media (max-width: $w) {
    width: size(374);
    height: size(464);
    margin-bottom: size(5);
  }
  @media (max-width: $mobile) {
    width: sizem(502);
    height: sizem(627);
    margin-bottom: sizem(20);
  }

  img{
    position: absolute;
    z-index: 0;
    top: 5%;
    left: 6%;
    width: 87%;
    height: 89%;
    min-height: 90%;
    object-fit: cover;
    object-position: center;
    border-radius: 9%;
    transform: rotate(4deg);
  }
  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url('/assets/img/filmcover.png') center no-repeat;
    background-size: contain;
  }
}

.popup-film__slider img{
  margin: 0 auto 5px auto;
  display: block;

  @media (max-width: $w) {
    margin-bottom: size(5);
  }
}

.popup-film__subtitle{
  font-family: 'adigiana_2regular';
  width: 70%;
  margin: 0 auto 10px auto;
  text-align: center;
  font-size: 60px;
  line-height: 0.6;
  position: relative;
  z-index: 2;

  @media (max-width: $w) {
    font-size: size(60);
    margin-bottom: size(10);
  }
  @media (max-width: $mobile) {
    font-size: sizem(60);
    margin-bottom: sizem(30);
  }
}

.popup-film__dsc{
  width: 69%;
  margin: 20px 10% 0 18%;
  text-align: center;
  font-size: 18px;

  @media (max-width: $w) {
    font-size: size(18);
    margin-top: size(20);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
    margin-bottom: sizem(20);
  }
}

.element-popup-film{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 273px;
  @media (max-width: $w) {
    width: size(273);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    transform: rotate(9deg);
    img{
      width: 100%;
    }
  }
}

.button-in-film{
  width: 100%;
  height: 82%;
  position: absolute;
  transform: rotate(-9deg);
  left: -3%;
  top: 0%;
  cursor: pointer;
}

.popup-film__button-wrapper{
  text-align: center;
  padding-top: 10px;
  animation: 1s anim6 infinite;
  padding-left: 7%;

  @media (max-width: $w) {
    padding-top: size(10);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(180);
    padding-left: 0;
  }
}

.popup-kviz{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
}

.popup-kviz.active{
  opacity: 1;
  visibility: visible;
}

.popup-kviz__img{
  position: absolute;
  left: 50%;
  margin-left: -150px;
  top: -160px;
  width: 934px;

  @media (max-width: $w) {
    margin-left: size(-150);
    top: size(-160);
    width: size(934);
  }
  @media (max-width: $mobile) {
    margin-left: sizem(-40);
    top: sizem(-160);
    width: sizem(934);
  }
}

.popup-kviz__popup{
  position: absolute;
  left: 50%;
  z-index: 2;
  margin-left: -586px;
  top: 176px;
  width: 726px;

  @media (max-width: $w) {
    margin-left: size(-586);
    top: size(176);
    width: size(726);
  }
  &.p2{
    width: 746px;
    top: 120px;
    margin-left: -583px;
    @media (max-width: $w) {
      margin-left: size(-583);
      width: size(746);
      top: size(120);
    }
    @media (max-width: $mobile) {
      margin-left: sizem(-473);
      width: sizem(746);
      top: sizem(120);
    }
  }

  &.p3{
    width: 726px;
    top: 0px;
    margin-left: -583px;
    @media (max-width: $w) {
      margin-left: size(-583);
      width: size(726);
    }
    @media (max-width: $mobile) {
      margin-left: sizem(-403);
      width: sizem(726);
    }
  }

  &.p4{
    width: 726px;
    top: 0px;
    margin-left: -583px;
    @media (max-width: $w) {
      margin-left: size(-583);
      width: size(726);
    }
    @media (max-width: $mobile) {
      margin-left: sizem(-473);
      width: sizem(726);
    }
  }
}

.element-popup-kviz{
  position: absolute;
  z-index: 3;
  left: 50%;
  margin-left: -473px;
  top: 556px;
  width: 285px;

  @media (max-width: $w) {
    margin-left: size(-473);
    top: size(556);
    width: size(285);
  }
  @media (max-width: $mobile) {
    margin-left: sizem(-363);
    top: sizem(556);
    width: sizem(285);
  }
}

.button-in-kviz{
  position: absolute;
  left: 2%;
  top: 0;
  width: 90%;
  height: 68%;
  cursor: pointer;
}

.footer{
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1434px;
  margin-left: -717px;
  padding-bottom: 160px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 18px;

  @media (max-width: $w) {
    width: size(1434);
    margin-left: size(-717);
    padding-bottom: size(160);
    font-size: size(18);
  }
  @media (max-width: $mobile) {
    width: sizem(590);
    margin-left: sizem(-295);
    padding-bottom: sizem(160);
    font-size: sizem(32);
    text-align: center;
  }
}

.footer a{
  color: inherit;
}

.footer a:hover{
  text-decoration: none;
}

.footer__logos{
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: $w) {
    margin-bottom: size(20);
  }
  display: none;
}

.footer__logos a{
  display: inline-block;
  margin-right: 19px;

  @media (max-width: $w) {
    margin-right: size(19);
  }
}

.footer__logo1{
  width: 123px;
  @media (max-width: $w) {
    width: size(123);
  }
}

.footer__logo2{
  width: 126px;
  @media (max-width: $w) {
    width: size(126);
  }
}

.footer__logo3{
  width: 101px;
  @media (max-width: $w) {
    width: size(101);
  }
}

.footer__logo4{
  width: 89px;
  @media (max-width: $w) {
    width: size(89);
  }
}

.footer__logo5{
  width: 85px;
  @media (max-width: $w) {
    width: size(85);
  }
}

.footer__logo6{
  width: 70px;
  @media (max-width: $w) {
    width: size(70);
  }
}

.footer__logo7{
  width: 74px;
  @media (max-width: $w) {
    width: size(74);
  }
}

.footer__logo8{
  width: 92px;
  @media (max-width: $w) {
    width: size(92);
  }
}

.popup-wrap{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 100px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @media (max-width: $w) {
    padding-top: size(100);
  }
}

.popup-wrap.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.popup-wrap__in{
  position: relative;
}

.popup-wrap__close{
  right: 24px;
  top: -60px;
  padding: 60px;
  width: 52px;
  transition: 300ms transform;
  position: absolute;
  z-index: 5;

  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }
  @media (max-width: $mobile) {
    width: sizem(72);
    right: sizem(-20);
    top: sizem(20);
    padding: sizem(60);
  }

  &:hover{
    transform: scale(0.8);
  }
}

.popup-wrap__container{
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: 300ms opacity, 300ms visibility;
}
.popup-wrap__container.active{
  opacity: 1;
  visibility: visible;
}
.popup-wrap__container .slick-active{
  z-index: 1;
}

.popup-recipe-title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #83b810;
  width: 100%;
  text-align: center;
  left: 0;
  transform: rotate(-10deg);
  top: -30px;
  font-size: 130px;
  line-height: 75px;
  margin-bottom: 35px;

  @media (max-width: $w) {
    top: size(-30);
    font-size: size(130);
    line-height: size(75);
    margin-bottom: size(35);
  }
  @media (max-width: $mobile) {
    top: sizem(120);
    font-size: sizem(130);
    line-height: sizem(75);
    margin-bottom: sizem(200);
  }
}

.popup__select{
  display: flex;
  justify-content: center;
  margin-bottom: 78px;

  @media (max-width: $w) {
    margin-bottom: size(78);
  }
}

.MuiOutlinedInput-root{
  fieldset{
    display: none !important;
  }
  position: relative;
  &::before{
    content: '';
    position: absolute;
    top: 10%;
    right: 5px;
    height: 78%;
    width: 100px;
    z-index: 8;
    border-radius: 0 66px 66px 0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

    @media (max-width: $w) {
      width: size(100);
      right: size(5);
      border-radius: 0 size(66) size(66) 0;
    }
    @media (max-width: $mobile) {
      width: sizem(130);
      right: sizem(11);
      border-radius: 0 sizem(66) sizem(66) 0;
      top: 21%;
      height: 55%;
    }
  }
}

.MuiInputBase-root{
  width: 371px;
  background: url('/assets/img/select.png') 50% 50% no-repeat;
  background-size: contain;
  @media (max-width: $w) {
    width: size(371);
  }
  @media (max-width: $mobile) {
    width: sizem(371);
  }
}
.MuiInputBase-root .MuiSelect-select.MuiInputBase-input{
  font-family: 'adigiana_2regular';
  color: #000;
  padding: 0;
  border: none;
  z-index: 7;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 70px;
  font-size: 34px;
  padding-left: 33px;
  width: 98%;

  @media (max-width: $w) {
    height: size(70);
    font-size: size(34);
    padding-left: size(33);
  }
  @media (max-width: $mobile) {
    height: sizem(70);
    font-size: sizem(34);
    padding-left: sizem(33);
  }
}

.Mui-selected{
  /*background: rgba(131, 184, 16, 0.3) !important;*/
  background: rgba(0, 0, 0, 0.15) !important;
}

.MuiButtonBase-root.MuiMenuItem-root{
  font-family: 'adigiana_2regular';
  font-size: 34px;

  @media (max-width: $w) {
    font-size: size(34);
  }
  @media (max-width: $mobile) {
    font-size: sizem(34);
  }
}

.popup__select__icon{
  background: url('/assets/img/arrow_down.png') 50% 50% no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  z-index: 8;
  right: 17px;
  margin-top: -16px;
  width: 34px;
  height: 32px;

  @media (max-width: $w) {
    right: size(17);
    margin-top: size(-16);
    width: size(34);
    height: size(32);
  }
}

.popup-recipe__bgs{
  width: 983px;
  height: 1506px;
  position: relative;

  @media (max-width: $w) {
    width: size(983);
    height: size(1506);
  }
  @media (max-width: $mobile){
    display: none;
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;

    &.active{
      opacity: 1;
    }
  }
}

.popup-recipe-slide{
  width: 680px;
  height: 770px;
  margin: 0 auto;

  @media (max-width: $w) {
    width: size(680);
    height: size(770);
  }
  @media (max-width: $mobile) {
    width: sizem(680);
    height: sizem(825);
    & > div{
      height: 100% !important;
    }
  }

  .popup-film__subtitle{
    width: auto;
  }

  .popup-film__dsc{
    width: auto;
  }
}

.popup-recipe__buttons{
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 1100px;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: 300ms opacity, 300ms visibility;

  @media (max-width: $w) {
    top: size(1100);
  }
  @media (max-width: $mobile) {
    display: none;
  }

  &.active{
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
  }
  .popup-recipe__button{
    width: auto;
    margin: 0 10px;

    @media (max-width: $w) {
      margin: 0 size(10);
    }

    .element-popup-recipe{
      width: auto;
      .button-in-recipe{
        transform: rotate(-5deg);
      }
      &.b2{
        width: 239px;
        @media (max-width: $w) {
          width: size(239);
        }
        @media (max-width: $mobile) {
          width: sizem(239);
        }
      }
      &.b3{
        width: 223px;
        @media (max-width: $w) {
          width: size(223);
        }
        @media (max-width: $mobile) {
          width: sizem(223);
        }
      }
      &.b4{
        width: 232px;
        margin-left: 12px;
        @media (max-width: $w) {
          width: size(232);
          margin-left: size(12);
        }
        @media (max-width: $mobile) {
          width: sizem(232);
        }
      }
      &.b5{
        width: 234px;
        margin-top: -2px;
        @media (max-width: $w) {
          width: size(234);
          margin-top: size(-2);
        }
        @media (max-width: $mobile) {
          width: sizem(234);
        }
      }
    }
  }
}

.popup-recipe__slider{
  position: relative;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: 300ms opacity, 300ms visibility;
  position: absolute !important;
  top: 300px;
  bottom: auto;
  width: 100%;
  @media (max-width: $w) {
    top: size(300);
  }
  @media (max-width: $mobile) {
    top: 34%;
  }
}
.popup-wrap__container.active .popup-recipe__slider.active{
  opacity: 1;
  visibility: visible;
}
.popup-recipe__slider .slick-track{
  display: flex;
}

.popup-recipe__slider .slick-arrow{
  font-size: 0;
  border: none;
  cursor: none;
  position: absolute;
  z-index: 3;
  top: 46%;
  margin-top: -100px;

  &.slick-disabled{
    opacity: 0;
  }

  @media (max-width: $w) {
    margin-top: size(-100);
  }
}

.popup-recipe__slider .slick-prev{
  width: 177px;
  height: 193px;
  background: url('/assets/img/arrow_left.png') center bottom no-repeat;
  background-size: contain !important;
  left: -4%;

  @media (max-width: $w) {
    width: size(177);
    height: size(193);
  }
  @media (max-width: $mobile) {
    width: sizem(177);
    height: sizem(193);
    left: -2%;
  }
}

.popup-recipe__slider .slick-next{
  width: 164px;
  height: 182px;
  background: url('/assets/img/arrow_right.png') center bottom no-repeat;
  background-size: contain !important;
  right: -5%;

  @media (max-width: $w) {
    width: size(164);
    height: size(182);
  }
  @media (max-width: $mobile) {
    width: sizem(164);
    height: sizem(182);
    right: -1%;
  }
}

.popup-recipe-slide > div > div:last-child{
  width: 8px;
  background: #fff;
  border-radius: 8px;
  right: 16px !important;

  @media (max-width: $w) {
    width: size(8);
    right: size(16) !important;
  }
  @media (max-width: $mobile) {
    display: none;
  }

  div{
    background: #83b810 !important;
    width: 24px !important;
    left: -9px;
    border-radius: 8px !important;

    @media (max-width: $w) {
      width: size(24) !important;
      left: size(-9);
      border-radius: size(8) !important;
    }
  }
}

.popup-recipe-big-img{
  max-width: 100%;
  width: 474px;
  height: 301px;
  margin-bottom: 20px;
  display: block;
  position: relative;
  @media (max-width: $w) {
    width: size(474);
    height: size(301);
    margin-bottom: size(20);
  }
  @media (max-width: $mobile) {
    width: sizem(474);
    height: sizem(301);
    margin: 0 auto sizem(40);
  }

  img{
    position: absolute;
    z-index: 0;
    top: 9%;
    left: 4%;
    width: 89%;
    height: 86%;
    min-height: 86%;
    object-fit: cover;
    object-position: center;
    border-radius: 15%;
    transform: rotate(5deg);
  }
  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url('/assets/img/recipe_frame.png') center no-repeat;
    background-size: contain;
  }
}

.popup-recipe-slide__in{
  padding: 0 15%;
  @media (max-width: $mobile) {
    padding: 0 10%;
  }
}

.popup-recipe-prd{
  font-size: 18px;
  text-align: center;
  font-family: 'Frederik';
  font-weight: bold;
  @media (max-width: $w) {
    font-size: size(18);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
  }
}

.popup-recipe-dsc{
  font-size: 18px;
  text-align: center;
  font-family: 'Frederik';
  margin-bottom: 30px;
  @media (max-width: $w) {
    font-size: size(18);
    margin-bottom: size(30);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
    margin-bottom: sizem(30);
  }
}

.popup-recipe-list{
  font-size: 18px;
  font-family: 'Frederik';
  margin-bottom: 30px;
  @media (max-width: $w) {
    font-size: size(18);
    margin-bottom: size(30);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
    margin-bottom: sizem(30);
  }
}

.popup-recipe-box{
  font-size: 18px;
  font-family: 'Frederik';
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  text-align: left;

  .popup-recipe-prd{
    text-align: left;
  }

  @media (max-width: $w) {
    font-size: size(18);
    margin-bottom: size(30);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
    margin-bottom: sizem(30);
    display: block;
  }


  .popup-recipe-img{
    margin-right: 23px;
    width: 225px;
    height: 149px;
    border: 16px solid #fff;
    border-radius: 50px;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: $w) {
      margin-right: size(23);
      width: size(225);
      height: size(149);
      border-width: size(16);
      border-radius: size(50);
    }
    @media (max-width: $mobile) {
      margin: 0 auto sizem(20);
      width: sizem(292);
      height: sizem(193);
      border-width: sizem(16);
      border-radius: sizem(50);
    }

    img{
      width: 100%;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
    }
    & + div{
      flex: 1;
    }
  }
}


.old{
  display: none;
}
.element48{
  left: 41.7%;
  top: 14.62%;
  animation: 2s anim3 infinite;

  width: 301px;
  @media (max-width: $w) {
    width: size(301);
  }
  @media (max-width: $mobile) {
    width: sizem(301);
    left: 45.3%;
    top: 19.7%;
  }
}
.element49{
  left: 41.9%;
  top: 13.62%;
  z-index: 4;
  animation: 1.2s anim6 infinite;

  width: 222px;
  @media (max-width: $w) {
    width: size(222);
  }
  @media (max-width: $mobile) {
    width: sizem(344);
    left: 45.4%;
    top: 18.1%;
  }
}
.button-in49{
  left: -40%;
  top: -45%;
  width: 160%;
  height: 155%;
  transform: rotate(-10deg);
}
.element50{
  left: 33.55%;
  top: 5.55%;
  transform-origin: bottom;
  animation: 2s anim9 infinite;

  width: 497px;
  @media (max-width: $w) {
    width: size(497);
  }
  @media (max-width: $mobile) {
    width: sizem(497);
    left: 38.55%;
    top: 12.3%;
  }
}
.element51{
  left: 10.45%;
  top: 67%;
  z-index: 4;
  transform-origin: bottom;
  animation: 2s anim6 infinite;

  width: 377px;
  @media (max-width: $w) {
    width: size(377);
  }
  @media (max-width: $mobile) {
    width: sizem(377);
    top: 77%;
  }
}

.element52{
  left: 15%;
  top: 61.3%;
  z-index: 0;
  transform-origin: bottom;
  animation: 7s anim5 infinite;

  width: 396px;
  @media (max-width: $w) {
    width: size(396);
  }
  @media (max-width: $mobile) {
    width: sizem(396);
    left: 13.5%;
    top: 72.9%;
  }
}
.element53{
  left: 74.2%;
  top: 76.4%;
  transform-origin: bottom right;
  animation: 2s anim14 infinite ease-in-out;

  width: 64px;
  @media (max-width: $w) {
    width: size(64);
  }
  @media (max-width: $mobile) {
    width: sizem(64);
    left: 76.2%;
    top: 85%;
  }
}
.element54{
  left: 75.35%;
  top: 76.45%;
  transform-origin: bottom right;
  animation: 2s 0.5s anim14 infinite ease-in-out;

  width: 49px;
  @media (max-width: $w) {
    width: size(49);
  }
  @media (max-width: $mobile) {
    width: sizem(49);
    left: 77%;
    top: 85%;
  }
}
.element55{
  left: 78.3%;
  top: 70.5%;
  z-index: 4;
  transform-origin: bottom right;
  animation: 2s anim14 infinite ease-in-out;

  width: 75px;
  @media (max-width: $w) {
    width: size(75);
  }
  @media (max-width: $mobile) {
    width: sizem(75);
    left: 79.8%;
    top: 79.8%;
  }
}
.element56{
  left: 79%;
  top: 71.83%;
  z-index: 4;
  transform-origin: center right;
  animation: 2s anim14 infinite ease-in-out;

  width: 85px;
  @media (max-width: $w) {
    width: size(85);
  }
  @media (max-width: $mobile) {
    width: sizem(85);
    left: 80.5%;
    top: 80.9%;
  }
}
.element57{
  left: 77.65%;
  top: 72.1%;
  z-index: 4;
  transform-origin: center right;
  animation: 2s anim14 infinite ease-in-out;

  width: 85px;
  @media (max-width: $w) {
    width: size(85);
  }
  @media (max-width: $mobile) {
    width: sizem(85);
    left: 79.4%;
    top: 81.2%;
  }
}
.element58{
  left: 78.65%;
  top: 66.04%;
  z-index: 2;

  width: 379px;
  @media (max-width: $w) {
    width: size(379);
  }
  @media (max-width: $mobile) {
    width: sizem(379);
    left: 80.15%;
    top: 76.6%;
  }
}

.element59{
  left: 69.8%;
  top: 63.6%;
  z-index: 0;
  animation: 2s anim9 infinite;
  transform-origin: 50% 100%;

  width: 638px;
  @media (max-width: $w) {
    width: size(638);
  }
  @media (max-width: $mobile) {
    width: sizem(638);
    left: 72%;
    top: 74.3%;
  }
}

.element60{
  left: 31.9%;
  top: 52.55%;
  z-index: 0;

  width: 157px;
  @media (max-width: $w) {
    width: size(157);
  }
  @media (max-width: $mobile) {
    width: sizem(157);
    left: 29.1%;
    top: 64.1%;
  }
}
.element61{
  left: 36.1%;
  top: 51.05%;
  z-index: 0;
  transform-origin: bottom;
  animation: 4s anim14 infinite ease-in-out;

  width: 402px;
  @media (max-width: $w) {
    width: size(402);
  }
  @media (max-width: $mobile) {
    width: sizem(402);
    left: 33.6%;
    top: 62.8%;
  }
}

.element62{
  left: 35.01%;
  top: 76%;
  z-index: 0;

  width: 677px;
  @media (max-width: $w) {
    width: size(677);
  }
  @media (max-width: $mobile) {
    width: sizem(710);
    left: 36.9%;
    top: 80.3%;
  }
}
.element63{
  left: 42.68%;
  top: 80.65%;
  z-index: 4;
  animation: 4s anim14 infinite ease-in-out;

  width: 438px;
  @media (max-width: $w) {
    width: size(438);
  }
  @media (max-width: $mobile) {
    width: sizem(438);
    left: 43.7%;
    top: 84.3%;
  }
}
.element64{
  left: 50.9%;
  top: 87.1%;
  z-index: 2;

  width: 87px;
  @media (max-width: $w) {
    width: size(87);
  }
  @media (max-width: $mobile) {
    width: sizem(87);
    top: 89.7%;
  }
}
.element65{
  left: 52.7%;
  top: 84.75%;
  z-index: 4;
  animation: 4s anim15 infinite;

  width: 214px;
  @media (max-width: $w) {
    width: size(214);
  }
  @media (max-width: $mobile) {
    width: sizem(214);
    left: 52.9%;
    top: 87.75%;
  }
}
.popup-start.active{
  opacity: 1 !important;
  visibility: visible !important;
}
.popup-start{
  .popup__close{
    top: -7px;
    right: -5px;
    @media (max-width: $w) {
      top: size(-7);
      right: size(-5);
    }
    @media (max-width: $mobile) {
      top: sizem(-95);
      right: sizem(-73);
    }
  }
}

.popup-gift{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 70px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @media (max-width: $w) {
    padding-top: size(70);
  }
}

.popup-gift.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.popup-gift-start__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #5186cc;
  width: 100%;
  text-align: center;
  left: -20px;
  transform: rotate(-8deg);
  top: -59px;
  font-size: 115px;
  line-height: 62px;
  margin-bottom: 0;

  @media (max-width: $w) {
    top: size(-59);
    font-size: size(115);
    line-height: size(62);
  }
  @media (max-width: $mobile) {
    top: 0;
    margin-top: sizem(110);
    font-size: sizem(115);
    line-height: sizem(62);
  }
  span{
    display: block;
    &:first-child{
      margin-left: -55px;
      @media (max-width: $w) {
        margin-left: size(-55);
      }
      @media (max-width: $mobile) {
        margin-left: 0;
        padding-left: 12%;
      }
    }
    &:nth-child(2){
      margin-left: 210px;
      @media (max-width: $w) {
        margin-left: size(210);
      }
      @media (max-width: $mobile) {
        padding-left: 0;
        margin-top: sizem(5);
      }
    }
    &:nth-child(3){
      margin-left: 35px;
      @media (max-width: $w) {
        margin-left: size(35);
      }
      @media (max-width: $mobile) {
        padding-left: 0;
        margin-top: sizem(5);
        margin-left: -10%;
      }
    }
  }
}
.popup-gift-start__text{
  font-family: 'adigiana_2regular';
  font-size: 30px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: -25px;
  min-height: 300px;

  @media (max-width: $w) {
    font-size: size(30);
    margin-top: size(-25);
    min-height: size(300);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    line-height: 0.9;
    margin-top: sizem(65);
  }
  & > div{
    margin-top: 30px;
    transform: rotate(-9deg);
    width: 50%;
    @media (max-width: $w) {
      margin-top: size(30);
    }
    @media (max-width: $mobile) {
      width: 70%;
      &:nth-child(2){
        transform: rotate(8deg);
        margin-top: sizem(90);
        width: 58%;
        margin-left: -14%;
      }
    }
  }
}

.element-popup-gift{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    transform: rotate(14deg);
    img{
      width: 100%;
    }
  }
}

.button-in-gift{
  width: 107%;
  height: 92%;
  position: absolute;
  transform: rotate(-5deg);
  left: -5%;
  top: -7%;
  cursor: pointer;
}

.popup-gift__button-wrapper{
  text-align: center;
  padding-top: 10px;
  animation: 1s anim6 infinite;
  padding-left: 7%;

  @media (max-width: $w) {
    padding-top: size(10);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(30);
    padding-left: 0;
  }
}
.popup-gift__imgbottom{
  @media (max-width: $mobile) {
    width: sizem(749);
    margin: sizem(-350) 0 sizem(-150);
    position: relative;
    right: sizem(-215);
    bottom: sizem(-25);
    z-index: 0;
    img{
      display: block;
      width: 100%;
    }
  }
}

.popup-gift__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
  @media (max-width: $mobile) {
    padding-bottom: 0 !important;
  }
}

.popup-gift.active .popup-gift__in{
  transform: scale(1);
}

.popup-gift__in .popup__close{
  right: 24px;
  top: -60px;
  padding: 60px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }

  img{
    width: 100%;
  }
}

.popup-gift__bgs{
  width: 983px;
  height: 1036px;
  position: relative;

  @media (max-width: $w) {
    width: size(983);
    height: size(1036);
  }
  @media (max-width: $mobile) {
    z-index: 2;
    width: sizem(366);
    height: auto;
    left: sizem(-155);
    top: sizem(-170);
  }
  &.start{
    width: 971px;
    height: 787px;

    @media (max-width: $w) {
      width: size(971);
      height: size(787);
    }
    @media (max-width: $mobile) {
      width: sizem(366);
      height: auto;
    }
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 300ms opacity, 300ms visibility;

    &.active{
      opacity: 1;
      visibility: visible;
    }
  }
}

.popup-gift__title{
  position: absolute;
  left: 28%;
  top: -60px;
  z-index: 4;

  width: 430px;

  @media (max-width: $w) {
    width: size(430);
    top: size(-60);
  }
}
.popup-gift-title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #5186cc;
  width: 100%;
  text-align: center;
  left: -25px;
  transform: rotate(-10deg);
  top: -62px;
  font-size: 114px;
  line-height: 66px;
  margin-bottom: 0;

  @media (max-width: $w) {
    left: size(-25);
    top: size(-62);
    font-size: size(114);
    line-height: size(66);
  }
  @media (max-width: $mobile) {
    top: 0;
    margin-top: sizem(60);
    font-size: sizem(115);
    line-height: sizem(62);
  }
  .line2{
    position: relative;
    left: 23px;
    @media (max-width: $w) {
      left: size(23);
    }
    @media (max-width: $mobile) {
      margin-top: sizem(15);
    }
  }
  .line3{
    font-size: 0.9228em;
    position: relative;
    left: 35px;
    top: -5px;
    @media (max-width: $w) {
      left: size(35);
      top: size(-5);
    }
    @media (max-width: $mobile) {
      margin-top: sizem(15);
    }
  }
}
.popup-gift__in .popup__select__icon{
  background-image: url('/assets/img/down_blue.png');

  @media (max-width: $mobile) {
    right: sizem(20);
    margin-top: sizem(-24);
    width: sizem(49);
    height: sizem(47);
  }
}
.popup-gift__in .popup__select{
  margin-bottom: 39px;
  padding-left: 10px;

  @media (max-width: $w) {
    margin-bottom: size(39);
    padding-left: size(10);
  }
  @media (max-width: $mobile) {
    margin-top: sizem(60);
    margin-bottom: sizem(39);
    padding-left: 0;
  }

  .MuiInputBase-root{
    width: 431px;
    background-image: url('/assets/img/select_big.png');
    @media (max-width: $w) {
      width: size(431);
    }
    @media (max-width: $mobile) {
      width: sizem(600);
    }
    .MuiSelect-select.MuiInputBase-input{
      @media (max-width: $mobile) {
        height: sizem(110);
        font-size: sizem(50);
        padding: 0 sizem(50);
      }
    }
  }
}
.popup-gift__slider .slick-track{
  display: flex;
}

.popup-gift__slider .slick-arrow{
  font-size: 0;
  border: none;
  cursor: none;
  position: absolute;
  z-index: 3;
  top: 46%;
  margin-top: -100px;

  &.slick-disabled{
    opacity: 0;
  }

  @media (max-width: $w) {
    margin-top: size(-100);
  }
}

.popup-gift__slider .slick-prev{
  width: 177px;
  height: 193px;
  background: url('/assets/img/left_blue.png') center bottom no-repeat;
  background-size: contain !important;
  left: -4%;

  @media (max-width: $w) {
    width: size(177);
    height: size(193);
  }
  @media (max-width: $mobile) {
    width: sizem(177);
    height: sizem(193);
    left: -2%;
  }
}

.popup-gift__slider .slick-next{
  width: 164px;
  height: 182px;
  background: url('/assets/img/right_blue.png') center bottom no-repeat;
  background-size: contain !important;
  right: -5%;

  @media (max-width: $w) {
    width: size(164);
    height: size(182);
  }
  @media (max-width: $mobile) {
    width: sizem(164);
    height: sizem(182);
    right: -1%;
  }
}
.popup-gift-slide{
  width: 560px;
  height: 500px;
  margin: 0 auto;

  @media (max-width: $w) {
    width: size(560);
    height: size(500);
  }
  @media (max-width: $mobile) {
    width: sizem(559);
    height: sizem(350);
  }
}
.popup-gift-slide__in{
  padding: 0;
}
.popup-gift__subtitle{
  font-family: 'adigiana_2regular';
  margin: 0 auto 20px auto;
  text-align: center;
  font-size: 58px;
  line-height: 0.7;

  @media (max-width: $w) {
    font-size: size(58);
    margin-bottom: size(20);
  }
  @media (max-width: $mobile) {
    display: none;
  }
}
.popup-gift-video{
  width: 474px;
  height: 301px;
  margin: 0 auto;
  display: block;
  position: relative;
  cursor: pointer;
  @media (max-width: $w) {
    width: size(474);
    height: size(301);
  }
  @media (max-width: $mobile) {
    width: sizem(559);
    height: sizem(350);
  }

  img{
    position: absolute;
    z-index: 0;
    top: 9%;
    left: 5%;
    width: 89%;
    height: 82%;
    min-height: 83%;
    object-fit: cover;
    object-position: center;
    border-radius: 14%;
    transform: rotate(2deg);
  }
  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url('/assets/img/giftframe.png') center no-repeat;
    background-size: contain;
  }

  &:after{
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    background: url('/assets/img/play.png') center no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 4;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: 300ms transform;

    @media (max-width: $w) {
      width: size(63);
      height: size(63);
    }
    @media (max-width: $mobile) {
      width: sizem(77);
      height: sizem(77);
    }
  }

  &:hover{
    &:after{
      transform: translate(-50%,-50%) scale(0.8);
    }
  }
}
.popup-gift-video-block{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  margin-top: -70px;
  @media (max-width: $w) {
    margin-top: size(-70);
  }
  @media (max-width: $mobile) {
    margin-top: 0;
    overflow: hidden;
  }

}
.popup-gift-video-block.active{
  opacity: 1;
  visibility: visible;
}
.popup-gift-video-block__closelayer{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.popup-gift-video-block__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
  z-index: 1;
  @media (max-width: $mobile) {
    padding-bottom: 0 !important;
  }
  .popup__close{
    right: -140px;
    top: -110px;
    @media (max-width: $w) {
      right: size(-140);
      top: size(-110);
    }
    @media (max-width: $mobile) {
      right: sizem(-30);
      top: sizem(-120) !important;
    }
  }
}

.popup-gift-video-block.active .popup-gift-video-block__in{
  transform: scale(1);
}
.popup-gift-video-block__frame{
  width: 728px;
  height: 410px;
  margin: 0 auto;
  display: block;
  position: relative;
  cursor: pointer;
  border: 30px solid #fff;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  border-radius: 30px;
  @media (max-width: $w) {
    width: size(728);
    height: size(410);
    border-width: size(30);
    border-radius: size(30);
    box-shadow: 0 size(5) size(20) rgba(0,0,0,0.2);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    height: sizem(366);
  }
  iframe{
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px;
    @media (max-width: $w) {
      border-radius: size(20);
    }
  }
  &::before{
    content: '';
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url('/assets/img/giftframe.png') center no-repeat;
    background-size: contain;
  }
}

.popup-fairytale{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
}

.popup-fairytale.active{
  opacity: 1;
  visibility: visible;
}

.popup-fairytale__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
  @media (max-width: $mobile) {
    padding-bottom: 0 !important;
  }
}

.popup-fairytale.active .popup-fairytale__in{
  transform: scale(1);
}

.popup-fairytale__in .popup__close{
  right: 24px;
  top: -60px;
  padding: 60px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }

  img{
    width: 100%;
  }
}

.popup-fairytale__bg{
  width: 983px;
  height: 1436px;
  position: relative;

  @media (max-width: $w) {
    width: size(983);
    height: size(1436);
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;

    &.active{
      opacity: 1;
    }
  }
}

.popup-fairytale__title{
  position: absolute;
  left: 22%;
  top: -19px;
  z-index: 4;

  width: 535px;

  @media (max-width: $w) {
    width: size(535);
    top: size(-19);
  }
}
.popup-fairytale-title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #e4483f;
  width: 100%;
  text-align: center;
  left: -22px;
  transform: rotate(-10deg);
  top: -12px;
  font-size: 130px;
  line-height: 75px;
  margin-bottom: 0;

  @media (max-width: $w) {
    left: size(-22);
    top: size(-12);
    font-size: size(130);
    line-height: size(75);
  }
  @media (max-width: $mobile) {
    left: sizem(-22);
    top: sizem(80);
    font-size: sizem(130);
    line-height: sizem(75);
    margin-bottom: sizem(120);
  }

  span{
    position: relative;
    font-size: 85px;
    left: 8px;
    top: -21px;

    @media (max-width: $w) {
      font-size: size(85);
      left: size(8);
      top: size(-21);
    }
    @media (max-width: $mobile) {
      font-size: sizem(85);
      left: sizem(8);
      top: sizem(-21);
    }
  }
  display: none;
  &.active{
    display: block;
  }
  &.i1{
    margin-top: 1%;
    span{
      display: inline-block;
      font-size: 1em;
      padding-left: 21%;
      margin-top: 1%;
    }
  }
  &.i1t{
    font-size: 104px;
    line-height: 0.55;
    margin-bottom: 15px;
    @media (max-width: $w) {
      font-size: size(104);
      margin-bottom: size(15);
    }
    span{
      display: block;
      &:nth-child(3){
        font-size: 0.96em;
      }
      &:nth-child(4){
        font-size: 0.96em;
        margin-top: 15px;
        @media (max-width: $w) {
          margin-top: size(15);
        }
      }
    }
  }
}
.popup-fairytale-audiolist{
  display: none;
  &.active{
    display: block;
    position: relative;
    z-index: 3;
  }
  @media (max-width: $mobile) {
    height: sizem(650);
    .popup-fairytale__button{
      padding-top: sizem(280);
      .button-in-fairytale{
        transform: none;
      }

    }
  }

  .popup-fairytale-slide{
    width: 680px;
    @media (max-width: $w) {
      width: size(680);
    }
    @media (max-width: $mobile) {
      width: sizem(650);
      height: sizem(650);
    }
  }
}
.popup-fairytale-audiolist__in{
  margin-top: 140px;
  @media (max-width: $w) {
    margin-top: size(140);
  }
  @media (max-width: $mobile) {
    margin-top: sizem(140);
    margin-bottom: sizem(50);
  }
}
.popup-fairytale-audio__empty{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-10deg);
  font-size: 50px;
  line-height: 0.7;
  margin-bottom: 0;
  padding: 200px 23%  0 27%;
  box-sizing: border-box;

  @media (max-width: $w) {
    font-size: size(50);
    padding-top: size(200);
  }
  @media (max-width: $mobile) {
    font-size: sizem(70);
    padding: 0 18%;
    height: 100%;
  }
}
.popup-fairytale-audiolist__in .popup-fairytale__slider .slick-prev{
  left: -4%;
  @media (max-width: $mobile) {
    left: -2%;
  }
}
.popup-fairytale-audiolist__in .popup-fairytale__slider .slick-next{
  right: -5%;
  @media (max-width: $mobile) {
    right: -1%;
  }
}

.popup-fairytale-loadblock{
  display: none;
  &.active{
    display: block;
  }
}
.popup-fairytale-loadblock__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #e4483f;
  width: 100%;
  text-align: center;
  left: -10px;
  transform: rotate(-10deg);
  top: -45px;
  font-size: 91px;
  line-height: 57px;
  margin-bottom: 0;

  @media (max-width: $w) {
    left: size(-10);
    top: size(-45);
    font-size: size(91);
    line-height: size(57);
  }
  @media (max-width: $mobile) {
    left: sizem(-22);
    top: sizem(80);
    font-size: sizem(104);
    line-height: sizem(57);
  }

  span{
    display: block;
  }
}
.popup-fairytale-loadblock__text{
  font-family: 'adigiana_2regular';
  font-size: 30px;
  line-height: 1;

  @media (max-width: $w) {
    font-size: size(30);
  }
  @media (max-width: $mobile) {
    font-size: sizem(60);
    margin-top: sizem(150);
  }

  div{
    text-align: center;

    &:nth-child(1){
      width: 45%;
      transform: rotate(-5deg);
      margin-left: 38%;
      margin-top: 30px;

      @media (max-width: $w) {
        margin-top: size(30);
      }
      @media (max-width: $mobile) {
        width: 80%;
        margin-left: 10%;
      }
    }
    &:nth-child(2){
      width: 47%;
      transform: rotate(3deg);
      margin-left: 34%;
      margin-top: 80px;

      @media (max-width: $w) {
        margin-top: size(80);
      }
      @media (max-width: $mobile) {
        width: 80%;
        margin-left: 10%;
      }
    }
  }
}
.popup-fairytale-loadblock__rules{
  font-family: 'adigiana_2regular';
  margin: 100px auto 0;
  width: 55%;

  @media (max-width: $w) {
    margin-top: size(100);
  }
  @media (max-width: $mobile) {
    margin-top: sizem(60);
    width: 80%;
  }

  .popup-fairytale-loadblock__rules-title{
    font-size: 45px;
    line-height: 0.83;
    color: #e4483f;
    text-align: center;
    margin: 0 auto;
    width: 90%;

    @media (max-width: $w) {
      font-size: size(45);
    }
    @media (max-width: $mobile) {
      font-size: sizem(72);
    }
  }
  ol{
    list-style: none;
    font-size: 26px;
    line-height: 1;
    counter-reset: ruleslist;
    margin-top: 30px;

    @media (max-width: $w) {
      font-size: size(26);
      margin-top: size(30);
    }
    @media (max-width: $mobile) {
      font-size: sizem(48);
    }

    li{
      margin-bottom: 15px;
      counter-increment: ruleslist;
      position: relative;
      margin-bottom: 35px;

      @media (max-width: $w) {
        margin-bottom: size(35);
      }
      @media (max-width: $mobile) {
        margin-bottom: sizem(25);
      }

      &::before{
        content: counter(ruleslist);
        position: absolute;
        right: 100%;
        top: 0;
        color: #e4483f;
        margin-right: 15px;

        @media (max-width: $w) {
          margin-right: size(15);
        }
        @media (max-width: $mobile) {
          margin-right: sizem(25);
        }
      }

      a{
        color: #e4483f;
      }
    }
  }

}

.popup-fairytale-loadblock__rules-bottom{
  font-family: 'adigiana_2regular';
  font-size: 24px;
  color: #000;
  text-align: center;
  margin-top: 27px;
  padding: 0 17%;

  @media (max-width: $w) {
    font-size: size(24);
    margin-top: size(27);
  }
  @media (max-width: $mobile) {
    font-size: sizem(48);
    margin-top: sizem(27);
    padding: 0 5%;
  }

  a{
    color: #000;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}
.popup-fairytale__form{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'adigiana_2regular';
  margin-top: 100px;
  font-size: 24px;
  @media (max-width: $w) {
    font-size: size(24);
    margin-top: size(100);
  }

  .input-text{
    font-family: 'adigiana_2regular';
    color: #000;
    padding: 0;
    border: none;
    z-index: 7;
    display: flex;
    background: url('/assets/img/select_small.png') 50% 50% no-repeat;
    background-size: contain;
    align-items: center;
    box-sizing: border-box;
    width: 301px;
    height: 70px;
    font-size: 24px;
    padding: 0 33px;
    outline: none;
    margin-bottom: 20px;

    @media (max-width: $w) {
      width: size(301);
      height: size(70);
      font-size: size(24);
      padding: 0 size(33);
      background-size: contain;
      margin-bottom: size(20);
    }
    @media (max-width: $mobile) {
      width: sizem(511);
      height: sizem(117);
      font-size: sizem(40);
      padding: 0 sizem(60);
    }

    &::placeholder {
      color: #000;
    }
  }
  .input-file{
    position: relative;
    margin-top: 13px;
    cursor: pointer;
    @media (max-width: $w) {
      margin-top: size(13);
    }
    @media (max-width: $mobile) {
      margin-top: sizem(13);
    }

    span{
      color: #e4483f;
      white-space: nowrap;
      text-decoration: underline;
      font-size: 24px;

      @media (max-width: $w) {
        font-size: size(24);
      }
      @media (max-width: $mobile) {
        font-size: sizem(48);
      }
    }
    input{
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    &:hover{
      span{
        text-decoration: none;
      }
    }
  }

}
.popup-fairytale__buttonload{
  padding-top: 50px !important;
  padding-left: 2%;

  @media (max-width: $w) {
    padding-top: size(50) !important;
  }
  @media (max-width: $mobile) {
    padding-top: sizem(50) !important;
  }
}

.popup-fairytale__slider .slick-track{
  display: flex;
}

.popup-fairytale__slider .slick-arrow{
  font-size: 0;
  border: none;
  cursor: none;
  position: absolute;
  z-index: 3;
  top: 24.5%;
  margin-top: -100px;

  &.slick-disabled{
    opacity: 0;
  }

  @media (max-width: $w) {
    margin-top: size(-100);
  }
}

.popup-fairytale__slider .slick-prev{
  width: 177px;
  height: 193px;
  background: url('/assets/img/left_lightred.png') center bottom no-repeat;
  background-size: contain !important;
  left: -33%;

  @media (max-width: $w) {
    width: size(177);
    height: size(193);
  }
  @media (max-width: $mobile) {
    width: sizem(177);
    height: sizem(193);
    left: -9%;
  }
}

.popup-fairytale__slider .slick-next{
  width: 164px;
  height: 182px;
  background: url('/assets/img/right_lightred.png') center bottom no-repeat;
  background-size: contain !important;
  right: -34%;

  @media (max-width: $w) {
    width: size(164);
    height: size(182);
  }
  @media (max-width: $mobile) {
    width: sizem(164);
    height: sizem(182);
    right: -9%;
  }
}
.popup-fairytale-slide > div:not(.popup-fairytale__button) > div:last-child{
  width: 8px;
  background: #fff;
  border-radius: 8px;
  right: 16px !important;

  @media (max-width: $w) {
    width: size(8);
    right: size(16) !important;
  }
  @media (max-width: $mobile) {
    display: none;
  }

  div{
    background: #e4483f !important;
    width: 24px !important;
    left: -9px;
    border-radius: 8px !important;

    @media (max-width: $w) {
      width: size(24) !important;
      left: size(-9);
      border-radius: size(8) !important;
    }
  }
}

.popup-fairytale-slide{
  width: 650px;
  height: 975px;
  margin: 0 auto;

  @media (max-width: $w) {
    width: size(650);
    height: size(975);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    height: sizem(520);
    & > div{
      height: 100% !important;
    }
  }
}
.popup-fairytale-slide__in{
  padding: 0 8% 0 10%;
  @media (max-width: $mobile) {
    padding: 0 11% 0 13%;
  }
  .popup-fairytale-audio{
    &[data-type="top"]{
      display: none;
    }
  }
  &.topaudiolist{
    .popup-fairytale-audio{
      display: none;
      &[data-type="top"]{
        display: flex;
      }
    }
    .popup-fairytale-audio__empty{
      display: none;
    }
  }
}
.popup-fairytale-slide__in-title{
  font-family: 'adigiana_2regular';
  color: #000;
  font-size: 36px;
  line-height: 0.8;
  text-align: center;
  padding-top: 10px;
  @media (max-width: $w) {
    font-size: size(36);
    padding-top: size(10);
  }
  @media (max-width: $mobile) {
    font-size: sizem(48);
    padding-top: sizem(30);
  }
}
.popup-fairytale__in .popup__select__icon{
  background-image: url('/assets/img/down_lightred.png');
}
.popup-fairytale__top{
  display: none;
  &.active{
    display: block;
  }
}
.popup-fairytale__in .popup__select{
  margin-bottom: 39px;
  padding-left: 10px;

  @media (max-width: $w) {
    margin-bottom: size(39);
    padding-left: size(10);
  }

  .MuiInputBase-root{
    width: 581px;
    background-image: url('/assets/img/select_big2.png');
    @media (max-width: $w) {
      width: size(581);
    }
    @media (max-width: $mobile) {
      width: sizem(600);
    }
    .MuiSelect-select.MuiInputBase-input{
      @media (max-width: $mobile) {
        height: sizem(110);
        font-size: sizem(50);
        padding: 0 sizem(50);
      }
    }
  }
}
.popup-fairytale__in .popup__select-label{
  text-align: center;
  font-family: 'adigiana_2regular';
  font-size: 27px;
  line-height: 1;
  color: #e4483f;

  @media (max-width: $w) {
    font-size: size(27);
  }
  @media (max-width: $mobile) {
    font-size: sizem(54);
  }
}
.popup-fairytale__start-rules{
  text-align: center;
  font-family: 'adigiana_2regular';
  color: #000;
  font-size: 32px;
  @media (max-width: $w) {
    font-size: size(32);
  }
  @media (max-width: $mobile) {
    font-size: sizem(54);
    padding-bottom: sizem(100);
  }
  a{
    color: #000;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}
.popup-fairytale__buttons{
  display: flex;
  justify-content: center;
  padding-left: 4%;

  @media (max-width: $mobile) {
    padding-bottom: sizem(30);
    flex-direction: column;
  }
  .popup-fairytale__button{
    width: auto;
    .element-popup-fairytale{
      width: auto;
      margin: 0;
    }
    &:nth-child(1){
      margin-top: 3%;
    }
    &:nth-child(2){

    }
    &:nth-child(3){
      margin-top: -3%;
    }
  }

  .element-popup-fairytale{
    &.b1{
      width: 219px;
      @media (max-width: $w) {
        width: size(219);
      }
      @media (max-width: $mobile) {
        width: sizem(438);
      }
    }
    &.b2{
      width: 233px;
      @media (max-width: $w) {
        width: size(233);
      }
      @media (max-width: $mobile) {
        width: sizem(466);
      }
    }
    &.b3{
      width: 224px;
      @media (max-width: $w) {
        width: size(224);
      }
      @media (max-width: $mobile) {
        width: sizem(448);
      }
    }
  }
}
.popup-fairytale__button{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
  animation: 2s anim6 infinite;

  @media (max-width: $w) {
    padding-top: size(10);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(10);
  }
  &.btop{
    position: absolute;
    top: -185px;
    padding-left: 4.5%;
    z-index: 5;
    @media (max-width: $w) {
      top: size(-185);
    }
    @media (max-width: $mobile) {
      top: 0;
      position: relative;
      padding-top: 0;
      margin-top: sizem(-45);
    }
    display: none;
    &.active{
      display: flex;
    }
  }
}
.popup-fairytale-audio{
  display: flex;
  align-items: center;
  width: 519px;
  height: 85px;
  border-radius: 30px;
  margin-bottom: 15px;
  background: #fff;
  padding: 0 15px;
  font-family: 'Frederik';
  font-size: 24px;
  line-height: 1;
  color: #000;

  @media (max-width: $w) {
    width: size(519);
    height: size(85);
    border-radius: size(30);
    margin-bottom: size(15);
    padding: 0 size(15);
    font-size: size(24);
  }
  @media (max-width: $mobile) {
    width: sizem(450);
    height: sizem(85);
    border-radius: sizem(30);
    margin-bottom: sizem(15);
    padding: 0 sizem(15);
    font-size: sizem(24);
  }

  &:hover{
    .popup-fairytale-audio__button{
      transform: scale(0.8);
    }
  }

  &.active{
    background-color: #e4483f;
    color: #fff;

    .popup-fairytale-audio__button{
      background-image: url('/assets/img/pause.png');
      background-size: 24px auto;

      @media (max-width: $w) {
        background-size: size(24) auto;
      }
    }
  }
}
.popup-fairytale-audio__num{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;

  @media (max-width: $w) {
    width: size(48);
    height: size(48);
  }
  @media (max-width: $mobile) {
    width: sizem(48);
    height: sizem(48);
  }
}
.popup-fairytale-audio__text{
  margin-left: 20px;
  flex: 1;

  @media (max-width: $w) {
    margin-left: size(20);
  }

  span{
    font-size: 0.66em;
    display: block;
  }
}
.popup-fairytale-audio__time{
  font-size: 0.66em;
  margin-left: 20px;
  width: 50px;

  @media (max-width: $w) {
    margin-left: size(20);
    width: size(50);
  }
}
.popup-fairytale-audio__button{
  width: 65px;
  height: 100%;
  background: url('/assets/img/play_red.png') center no-repeat;
  background-size: 22px auto;
  transition: 300ms transform;

  @media (max-width: $w) {
    width: size(65);
    background-size: size(22) auto;
  }
}

.element-popup-fairytale{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    img{
      width: 100%;
    }
  }
  transition: 300ms opacity;
  &.disabled{
    opacity: 0.5;
  }

  &.btop{
    width: 388px;
    @media (max-width: $w) {
      width: size(388);
    }
    @media (max-width: $mobile) {
      width: sizem(388);
    }
  }
}

.button-in-fairytale{
  width: 100%;
  height: 82%;
  position: absolute;
  transform: rotate(-5deg);
  left: -3%;
  top: 0%;
  cursor: pointer;
}

.popup-fairytale-record{
  display: none;
  &.active{
    display: block;
  }
}
.popup-fairytale-record__in{
}
.popup-fairytale-record__title{
  font-family: 'adigiana_2regular';
  color: #000;
  font-size: 27px;
  line-height: 0.88;
  margin-bottom: 22px;
  text-align: center;

  @media (max-width: $w) {
    font-size: size(27);
    margin-bottom: size(22);
  }
  @media (max-width: $mobile) {
    font-size: sizem(50);
    margin-bottom: sizem(22);
  }

  a{
    display: inline-block;
    position: relative;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      background: url('/assets/img/underline.png') center no-repeat;
      background-size: contain;
      width: 100%;
      height: 6px;

      @media (max-width: $w) {
        height: size(6);
        bottom: size(-8);
      }
      @media (max-width: $mobile) {
        height: sizem(11);
        bottom: sizem(-8);
      }
    }
    &:hover{
      &:before{
        opacity: 0;
      }
    }
  }
}
.popup-fairytale-record__text{
  width: 650px;
  height: 640px;
  margin: 0 auto;

  @media (max-width: $w) {
    width: size(650);
    height: size(640);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    height: sizem(520);
  }
}
.popup-fairytale-record__text-in{
  padding: 0 12%;
}
.popup-fairytale-record__text > div > div:last-child{
  width: 8px;
  background: #fff;
  border-radius: 8px;
  right: 16px !important;

  @media (max-width: $w) {
    width: size(8);
    right: size(16) !important;
  }

  div{
    background: #e4483f !important;
    width: 24px !important;
    left: -9px;
    border-radius: 8px !important;

    @media (max-width: $w) {
      width: size(24) !important;
      left: size(-9);
      border-radius: size(8) !important;
    }
  }
}

.popup-fairytale-record__buttons{
  margin-top: 25px;
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: $w) {
    margin-top: size(25);
  }
  @media (max-width: $mobile) {
    margin-top: sizem(60);
  }
}
.element-popup-fairytale-load{
  position: relative;
  margin: 0;
  display: inline-block;

  width: 240px;
  @media (max-width: $w) {
    width: size(240);
  }
  @media (max-width: $mobile) {
    width: sizem(350);
  }
}
.button-in-fairytale-load{
  width: 96%;
  height: 68%;
  position: absolute;
  transform: rotate(6deg);
  left: 0;
  top: 5%;
  cursor: pointer;
}
.element-popup-fairytale-listen{
  position: relative;
  margin: 0;
  display: inline-block;

  width: 240px;
  @media (max-width: $w) {
    width: size(240);
  }
  @media (max-width: $mobile) {
    width: sizem(350);
  }
}
.button-in-fairytale-listen{
  width: 96%;
  height: 68%;
  position: absolute;
  transform: rotate(-5deg);
  left: 0;
  top: 5%;
  cursor: pointer;
}
.popup-fairytale-record__text{

}



.popup-decoration{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.popup-decoration.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.popup-decoration__imgbottom{
  @media (max-width: $mobile) {
    width: sizem(750);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    img{
      display: block;
      width: 100%;
    }
  }
}
.popup-decoration__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
  @media (max-width: $mobile) {
    overflow: hidden !important;
  }
}

.popup-decoration.active .popup-decoration__in{
  transform: scale(1);
}

.popup-decoration__in .popup__close{
  right: 24px;
  top: -60px;
  padding: 60px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }

  img{
    width: 100%;
  }
}
.popup-decoration__in .popup__select{
  margin-bottom: 39px;

  @media (max-width: $w) {
    margin-bottom: size(39);
  }
  @media (max-width: $mobile) {
    margin-top: sizem(100);
    margin-bottom: sizem(39);
    padding-left: 0;
  }

  .MuiInputBase-root{
    @media (max-width: $mobile) {
      width: sizem(600) !important;
    }
    .MuiSelect-select.MuiInputBase-input{
      @media (max-width: $mobile) {
        height: sizem(110) !important;
        font-size: sizem(50) !important;
        padding: 0 sizem(50) !important;
      }
    }
  }
}
.popup-decoration__in .popup__select__icon{
  background-image: url('/assets/img/down_lightblue.png');
}
.popup-decoration__bg{
  width: 983px;

  @media (max-width: $w) {
    width: size(983);
  }
}
.popup-decoration-start__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #21c2ce;
  width: 100%;
  text-align: center;
  left: -20px;
  transform: rotate(-8deg);
  top: -59px;
  font-size: 94px;
  line-height: 66px;
  margin-bottom: 0;

  @media (max-width: $w) {
    top: size(-59);
    font-size: size(94);
    line-height: size(66);
  }
  @media (max-width: $mobile) {
    top: sizem(110);
    font-size: sizem(94);
    line-height: sizem(66);
    transform: rotate(3deg);
    left: 0;
  }
  span{
    display: block;
  }
}
.popup-decoration-start__text{
  font-family: 'adigiana_2regular';
  font-size: 40px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: -25px;
  min-height: 400px;

  @media (max-width: $w) {
    font-size: size(40);
    margin-top: size(-25);
    min-height: size(400);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    margin-top: sizem(140);
    min-height: unset;
  }
  & > div{
    transform: rotate(-9deg);
    width: 45%;
    &:nth-child(1){
      padding-left: 20%;
      transform: rotate(-5deg);
      @media (max-width: $mobile) {
        width: 65%;
        transform: rotate(-2deg);
        padding-left: 0%;
        margin-top: sizem(60);
      }
    }
    &:nth-child(2){
      margin: 50px 0 40px;
      padding-left: 20%;
      transform: rotate(5deg);
      @media (max-width: $w) {
        margin: size(50) 0 size(40);
      }
      @media (max-width: $mobile) {
        width: 65%;
        margin-top: sizem(80);
        padding-left: 0%;
      }
    }
  }
}
.element-popup-decoration{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    transform: rotate(9deg);
    img{
      width: 100%;
    }
  }
}

.button-in-decoration{
  width: 107%;
  height: 104%;
  position: absolute;
  transform: rotate(2deg);
  left: -5%;
  top: -14%;
  cursor: pointer;
}

.popup-decoration__button-wrapper{
  text-align: center;
  padding-top: 10px;
  animation: 1s anim6 infinite;
  padding-left: 7%;

  @media (max-width: $w) {
    padding-top: size(10);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(30);
    padding-left: 0;
  }
}

.popup-decoration__title{
  position: absolute;
  left: 22%;
  top: -31px;
  z-index: 4;

  width: 501px;

  @media (max-width: $w) {
    width: size(501);
    top: size(-31);
  }
}
.popup-decoration-title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #21c2ce;
  width: 100%;
  text-align: center;
  left: -17px;
  transform: rotate(-10deg);
  top: -12px;
  font-size: 130px;
  line-height: 75px;
  margin-bottom: 35px;

  @media (max-width: $w) {
    left: size(-17);
    top: size(-12);
    font-size: size(130);
    line-height: size(75);
    margin-bottom: size(35);
  }
  @media (max-width: $mobile) {
    left: sizem(-8);
    top: sizem(80);
    font-size: sizem(130);
    line-height: sizem(75);
    margin-bottom: sizem(170);
  }

  span{
    position: relative;
    left: 10px;
    letter-spacing: -0.03em;

    @media (max-width: $w) {
      left: size(10);
    }
  }
}
.popup-decoration__slider .slick-track{
  display: flex;
}

.popup-decoration__slider .slick-arrow{
  font-size: 0;
  border: none;
  cursor: none;
  position: absolute;
  z-index: 3;
  top: 17%;
  margin-top: -100px;

  &.slick-disabled{
    opacity: 0;
  }

  @media (max-width: $w) {
    margin-top: size(-100);
  }
}

.popup-decoration__slider .slick-prev{
  width: 177px;
  height: 193px;
  background: url('/assets/img/left_lightblue.png') center bottom no-repeat;
  background-size: contain !important;
  left: -4%;

  @media (max-width: $w) {
    width: size(177);
    height: size(193);
  }
  @media (max-width: $mobile) {
    width: sizem(177);
    height: sizem(193);
    left: -2%;
  }
}

.popup-decoration__slider .slick-next{
  width: 164px;
  height: 182px;
  background: url('/assets/img/right_lightblue.png') center bottom no-repeat;
  background-size: contain !important;
  right: -5%;

  @media (max-width: $w) {
    width: size(164);
    height: size(182);
  }
  @media (max-width: $mobile) {
    width: sizem(164);
    height: sizem(182);
    right: -1%;
  }
}
.popup-decoration-slide > div > div:last-child{
  width: 8px;
  background: #fff;
  border-radius: 8px;
  right: 16px !important;

  @media (max-width: $w) {
    width: size(8);
    right: size(16) !important;
  }
  @media (max-width: $mobile) {
    display: none;
  }

  div{
    background: #21c2ce !important;
    width: 24px !important;
    left: -9px;
    border-radius: 8px !important;

    @media (max-width: $w) {
      width: size(24) !important;
      left: size(-9);
      border-radius: size(8) !important;
    }
  }
}
.popup-decoration-big-img{
  width: 474px;
  height: 601px;
  margin: 0 auto 40px;
  display: block;
  position: relative;
  @media (max-width: $w) {
    width: size(474);
    height: size(601);
    margin: 0 auto size(40);
  }
  @media (max-width: $mobile) {
    width: sizem(416);
    height: sizem(543);
    margin: 0 auto sizem(40);
  }

  img{
    position: absolute;
    z-index: 0;
    top: 4%;
    left: 4%;
    width: 89%;
    height: 93%;
    min-height: 93%;
    object-fit: cover;
    object-position: center;
    border-radius: 7%;
    transform: rotate(2deg);
  }
  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url('/assets/img/decorframe.png') center no-repeat;
    background-size: contain;
  }
  &.reverse{
    img{
      top: 5%;
      left: 7%;
      height: 91%;
      min-height: 91%;
      transform: rotate(-2deg);
    }
    &::before{
      transform: scale(-1,1);
    }
  }
}
.popup-decoration-slide{
  width: 650px;
  height: 975px;
  margin: 0 auto;

  @media (max-width: $w) {
    width: size(650);
    height: size(975);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    height: sizem(865);
    & > div{
      height: 100% !important;
    }
  }
}
.popup-decoration-slide__in{
  padding: 0 12%;
}
.popup-decoration-dsc{
  font-size: 18px;
  text-align: center;
  font-family: 'Frederik';
  margin-bottom: 40px;
  @media (max-width: $w) {
    font-size: size(18);
    margin-bottom: size(40);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
    margin-bottom: sizem(40);
  }
}


.popup-music{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 70px;
  opacity: 0;
  visibility: hidden;
}

.popup-music.active{
  opacity: 1;
  visibility: visible;
}

.popup-music__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
}

.popup-music.active .popup-music__in{
  transform: scale(1);
}

.popup-music__in .popup__close{
  right: 0;
  top: -38px;
  padding: 30px;
  padding-left: 0;

  width: 73px;
  @media (max-width: $w) {
    width: size(73);
    top: size(-38);
    padding: size(30);
    padding-left: 0;
  }
  @media (max-width: $mobile) {
    width: sizem(72) !important;
    top: 0 !important;
    padding: sizem(30) !important;
  }

  img{
    width: 100%;
  }
}

.popup-music__in .popup__hide{
  position: absolute;
  right: 105px;
  top: -30px;
  padding: 30px;
  padding-right: 0;
  transition: 300ms transform;
  cursor: pointer;
  z-index: 4;

  width: 74px;
  @media (max-width: $w) {
    width: size(74);
    right: size(105);
    top: size(-30);
    padding: size(30);
    padding-right: 0;
  }
  @media (max-width: $mobile) {
    display: none;
  }

  img{
    width: 100%;
  }
}
.popup-music__in .popup__hide:hover{
  transform: scale(0.8);
}

.popup-music__bg{
  width: 983px;

  @media (max-width: $w) {
    width: size(983);
  }
}

.popup-music__title{
  position: absolute;
  left: 17%;
  top: -57px;
  z-index: 4;

  width: 597px;

  @media (max-width: $w) {
    width: size(597);
    top: size(-57);
  }
  @media (max-width: $mobile) {
    width: sizem(597);
    top: sizem(50);
    left: 6%;
  }
}
.popup-music__text{
  color: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  top: 140px;
  z-index: 4;
  font-family: 'adigiana_2regular';
  font-size: 24px;
  text-align: center;
  padding-left: 1%;
  @media (max-width: $w) {
    font-size: size(24);
    top: size(140);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    line-height: 0.8;
    padding: 0 2%;
    box-sizing: border-box;
    top: sizem(220);
    color: #000;
  }
}
.popup-music__frame{
  position: absolute;
  top: 190px;
  left: 17%;
  width: 68%;
  @media (max-width: $w) {
    top: size(190);
  }
  @media (max-width: $mobile) {
    top: sizem(300);
    width: 90%;
    left: 5%;
  }
  iframe{
    width: 100%;
    height: 985px;
    @media (max-width: $w) {
      height: size(985);
    }
    @media (max-width: $mobile) {
      height: sizem(850);
    }
  }
}
.popup-music__button{
  position: absolute;
  left: 44%;
  top: 1220px;

  @media (max-width: $w) {
    top: size(1220);
  }
  @media (max-width: $mobile) {
    top: sizem(1144);
    left: 17%;
  }
}
.element-popup-music{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 200px;
  @media (max-width: $w) {
    width: size(200);
  }
  @media (max-width: $mobile) {
    width: sizem(500);
    img{
      width: 100%;
    }
  }
}

.button-in-music{
  width: 95%;
  height: 67%;
  position: absolute;
  transform: rotate(-5deg);
  left: 0;
  top: 5%;
  cursor: pointer;
}

.slick-arrow{
  transition: 300ms opacity,300ms transform;

  &:hover{
    transform: scale(0.8);
  }
}


.popup-mafia,
.popup-mafia-alert{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @media (max-width: $w) {
    padding-top: size(50);
  }
}

.popup-mafia.active,
.popup-mafia-alert.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.popup-mafia__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #ed251d;
  width: 100%;
  text-align: center;
  transform: rotate(-11deg);
  margin-left: -25px;
  font-size: 168px;
  line-height: 79px;
  margin-bottom: 0;

  @media (max-width: $w) {
    margin-left: size(-25);
    font-size: size(168);
    line-height: size(79);
  }
  @media (max-width: $mobile) {
    margin-top: sizem(115);
    margin-left: sizem(-15);
    font-size: sizem(168);
    line-height: sizem(79);
  }
}

.mafia-small{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > div{
    margin-right: 20px;
    margin-bottom: 7px;
  }

  @media (max-width: $w) {
    margin-right: size(20);
    margin-bottom: size(10);
  }
}

.popup-mafia__text{
  font-family: 'adigiana_2regular';
  font-size: 38px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 19px;
  min-height: 300px;

  @media (max-width: $w) {
    font-size: size(38);
    margin-top: size(19);
    min-height: size(300);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    line-height: 0.9;
    margin-top: sizem(100);
  }
  & > div{
    margin-top: 20px;
    width: 63%;
    @media (max-width: $w) {
      margin-top: size(20);
    }
    @media (max-width: $mobile) {
      margin-top: sizem(30);
        width: 80%;
    }
    &:nth-child(1){
    }
    &:nth-child(2){
      transform: rotate(3deg);
      margin-left: 3%;
      margin-top: 20px;
      @media (max-width: $w) {
        margin-top: size(20);
      }
      @media (max-width: $mobile) {
        margin-top: sizem(30);
      }
    }
    &:nth-child(3){
      @media (max-width: $mobile) {
        width: 90%;
      }
    }
    &.red{
      color: #ed251d;
    }
  }
}

.element-popup-mafia{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    img{
      width: 100%;
    }
  }
  transition: 300ms opacity;
  &.disabled{
    opacity: 0.5;
  }
}

.button-in-mafia{
  width: 100%;
  height: 82%;
  position: absolute;
  transform: rotate(-5deg);
  left: -3%;
  top: 0%;
  cursor: pointer;
}

.popup-mafia__button-wrapper{
  text-align: center;
  padding-top: 20px;
  animation: 1s anim6 infinite;
  padding-left: 3%;
  display: none;

  @media (max-width: $w) {
    padding-top: size(20);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(50);
  }
  &.active{
    display: block;
  }
}

.popup-mafia__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
}

.popup-mafia.active .popup-mafia__in,
.popup-mafia-alert.active .popup-mafia__in{
  transform: scale(1);
}

.popup-mafia__in .popup__close{
  right: 24px;
  top: -60px;
  padding: 60px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }

  img{
    width: 100%;
  }
}

.popup-mafia__bgs{
  width: 983px;
  height: 1055px;
  position: relative;

  @media (max-width: $w) {
    width: size(983);
    height: size(1055);
  }
  @media (max-width: $mobile) {
    width: sizem(659);
    height: auto;
    position: absolute;
    left: sizem(-205);
    top: sizem(188);
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 300ms opacity, 300ms visibility;

    &.active{
      opacity: 1;
      visibility: visible;
    }
  }
}
.popup-mafia__wrap{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.popup-mafia__form{
  display: none;
  font-family: 'adigiana_2regular';
  color: #000;
  font-size: 24px;

  @media (max-width: $w) {
    font-size: size(24);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
  }

  &.active{
    display: block;
  }

  .popup-mafia__form-line > input{
    pointer-events: auto !important;
  }

  input{
    font-family: 'adigiana_2regular';
    color: #000;
    padding: 0;
    border: none;
    z-index: 7;
    display: flex;
    background: url('/assets/img/select_small.png') 50% 50% no-repeat;
    background-size: contain;
    align-items: center;
    box-sizing: border-box;
    width: 301px;
    height: 70px;
    font-size: 24px;
    padding: 0 33px;
    outline: none;
    margin-right: 19px;

    -webkit-touch-callout: default !important;
    -webkit-user-select: auto !important;
     -khtml-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;

    @media (max-width: $w) {
      width: size(301);
      height: size(70);
      font-size: size(24);
      padding: 0 size(33);
      margin-right: size(19);
    }
    @media (max-width: $mobile) {
      width: sizem(511);
      height: sizem(117);
      font-size: sizem(40);
      padding: 0 sizem(60);
    }

    &:last-child{
      margin-right: 0;
    }

    &::placeholder {
      color: #000;
    }
  }

  .MuiInputBase-root{
    width: 301px;
    background-image: url('/assets/img/select_small2.png');
    @media (max-width: $w) {
      width: size(301);
    }
    @media (max-width: $mobile) {
      width: sizem(511);
    }
    .MuiSelect-select.MuiInputBase-input{
      font-size: 24px;

      @media (max-width: $w) {
        font-size: size(24);
      }
      @media (max-width: $mobile) {
        height: sizem(117);
        font-size: sizem(40);
        padding: 0 sizem(60) 0 sizem(60);
      }
    }
  }
  .popup__select{
    margin-bottom: 0;
    @media (max-width: $mobile) {
      margin-top: sizem(20);
    }
  }

  .popup-mafia__form-line:nth-child(2){
    @media (max-width: $mobile) {
      margin-top: sizem(20);
    }
  }

  .popup__select__icon{
    background-image: url('/assets/img/down_red.png');
    background-size: contain;
    @media (max-width: $w) {
      right: size(17);
      margin-top: size(-16);
      width: size(34);
      height: size(32);
    }
    @media (max-width: $mobile) {
      right: sizem(40);
      margin-top: sizem(-24);
      width: sizem(49);
      height: sizem(47);
    }
  }

  .popup-mafia__button-wrapper{
    padding-top: 60px;
    @media (max-width: $w) {
      padding-top: size(60);
    }
    @media (max-width: $mobile) {
      padding-top: sizem(60);
    }
  }
}
.popup-mafia__form-line{
  display: flex;
  justify-content: center;
  margin-top: 22px;

  @media (max-width: $w) {
    margin-top: size(22);
  }
  @media (max-width: $mobile) {
    flex-direction: column;
    align-items: center;
    margin-top: sizem(30);
  }
}
.popup-mafia__form-text{
  padding: 21px 17% 0;
  text-align: center;

  @media (max-width: $w) {
    padding-top: size(21);
  }
  @media (max-width: $mobile) {
    padding: sizem(24) 15% 0;
  }

  a{
    text-decoration: underline;
    color: #000;

    &:hover{
      text-decoration: none;
    }
  }
}
.popup-mafia__form-message{
  text-shadow: 1px 1px #fff;
  font-size: 1.2em;
  @media (max-width: $mobile) {
    font-size: 1.5em;
  }
  &.success{
    margin-top: -15px;
    @media (max-width: $w) {
      margin-top: size(-15);
    }
    @media (max-width: $mobile) {
      margin-top: 0;
    }
    &::before{
      content: '✓';
      display: inline-block;
      vertical-align: baseline;
      font-weight: 700;
      font-size: 1.5em;
      color: #63c431;
      @media (max-width: $w) {
        margin-right: size(10);
      }
      @media (max-width: $mobile) {
        margin-right: sizem(10);
      }
    }
  }
}
.popup-mafia__imgbottom{
  @media (max-width: $mobile) {
    width: sizem(749);
    margin: 0 auto;
    padding-bottom: sizem(70);
    img{
      display: block;
      width: 100%;
      margin-left: sizem(20)
    }
  }
}

.popup-melody{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @media (max-width: $w) {
    padding-top: size(50);
  }
}

.popup-melody.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.popup-melody__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #3471fa;
  width: 100%;
  text-align: center;
  transform: rotate(-9deg);
  margin-top: 0;
  margin-left: -15px;
  font-size: 115px;
  line-height: 85px;
  margin-bottom: 0;

  @media (max-width: $w) {
    margin-left: size(-15);
    font-size: size(115);
    line-height: size(85);
  }
  @media (max-width: $mobile) {
    margin-left: sizem(-43);
    font-size: sizem(115);
    line-height: sizem(85);
    margin-top: sizem(102);
  }

  span{
    display: block;
    &:nth-child(1){

    }
    &:nth-child(2){
      font-size: 120px;
      padding-left: 5%;
      @media (max-width: $w) {
        font-size: size(120);
      }
      @media (max-width: $mobile) {
        font-size: sizem(120);
      }
    }
  }
}
.popup-melody__text{
  font-family: 'adigiana_2regular';
  font-size: 32px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;

  @media (max-width: $w) {
    font-size: size(32);
    margin-top: size(30);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    line-height: 0.9;
    margin-top: sizem(150);
  }
  & > div{
    margin-top: 30px;
    width: 62%;
    @media (max-width: $w) {
      margin-top: size(30);
    }
    &:nth-child(1){
      padding-right: 12%;
      @media (max-width: $mobile) {
        width: 54%;
        padding-right: 35%;
        transform: rotate(-4deg);
      }
    }
    &:nth-child(2){
      transform: rotate(-3deg);
      margin-top: 20px;
      width: 55%;
      @media (max-width: $w) {
        margin-top: size(20);
      }
      @media (max-width: $mobile) {
        width: 83%;
        padding-right: 5%;
        transform: rotate(10deg);
        margin-top: sizem(100);
      }
    }
    &:nth-child(3){
      transform: rotate(3deg);
      padding-left: 2%;
      @media (max-width: $mobile) {
        width: 83%;
        padding-right: 5%;
        transform: rotate(7deg);
        margin-top: sizem(70);
      }
    }
  }
  &.popup-melody__text_bottom{
    margin-top: 0;
    & > div{
      transform: rotate(-3deg);
      width: 45%;
      margin-top: 0;
      position: relative;
      left: 15%;
      @media (max-width: $mobile) {
        width: 80%;
        left: 0;
        padding-left: 0;
        height: auto;
        padding-bottom: sizem(70);
        padding-right: 0;
      }
    }
  }
}

.element-popup-melody{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    transform: rotate(10deg);
    img{
      width: 100%;
    }
  }
}

.button-in-melody{
  width: 100%;
  height: 82%;
  position: absolute;
  transform: rotate(-5deg);
  left: -3%;
  top: 0%;
  cursor: pointer;
}

.popup-melody__imgbottom{
  @media (max-width: $mobile) {
    width: sizem(391);
    margin: 0 auto;
    padding-bottom: sizem(70);
    img{
      display: block;
      width: 100%;
    }
  }
}

.popup-melody__button-wrapper{
  text-align: center;
  padding-top: 30px;
  animation: 1s anim6 infinite;
  padding-left: 3%;
  display: none;

  @media (max-width: $w) {
    padding-top: size(30);
  }
  &.active{
    display: block;
  }
}

.popup-melody__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
}

.popup-melody.active .popup-melody__in{
  transform: scale(1);
}

.popup-melody__in .popup__close{
  right: 24px;
  top: -60px;
  padding: 60px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }

  img{
    width: 100%;
  }
}

.popup-melody__bgs{
  width: 983px;
  height: 1055px;
  position: relative;

  @media (max-width: $w) {
    width: size(983);
    height: size(1055);
  }
  @media (max-width: $mobile) {
    width: sizem(416);
    height: auto;
    position: absolute;
    right: sizem(-75);
    top: sizem(245);
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 300ms opacity, 300ms visibility;

    &.active{
      opacity: 1;
      visibility: visible;
    }
  }
}
.popup-melody__wrap{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.popup-melody__form{
  display: none;
  &.active{
    display: block;
  }

  input{
    font-family: 'adigiana_2regular';
    color: #000;
    padding: 0;
    border: none;
    z-index: 7;
    display: flex;
    background: url('/assets/img/select_small.png') 50% 50% no-repeat !important;
    background-size: contain;
    align-items: center;
    box-sizing: border-box;
    width: 301px;
    height: 70px;
    font-size: 24px;
    padding: 0 33px;
    outline: none;
    margin-right: 19px;

    @media (max-width: $w) {
      width: size(301);
      height: size(70);
      font-size: size(24);
      padding: 0 size(33);
      margin-right: size(19);
    }

    &::placeholder {
      color: #000;
    }
  }

  .MuiInputBase-root{
    width: 291px;
    background-image: url('/assets/img/select_small2.png');
    @media (max-width: $w) {
      width: size(291);
    }
    .MuiSelect-select.MuiInputBase-input{
      font-size: 24px;

      @media (max-width: $w) {
        font-size: size(24);
      }
    }
  }
  .popup__select{
    margin-bottom: 0;
  }
  .popup__select__icon{
    background-image: url('/assets/img/down_red.png');
    background-size: contain;
    @media (max-width: $w) {
      right: size(17);
      margin-top: size(-16);
      width: size(34);
      height: size(32);
    }
  }

  .popup-melody__button-wrapper{
    padding-top: 60px;
    @media (max-width: $w) {
      padding-top: size(60);
    }
  }
}
.popup-melody__form-line{
  display: flex;
  justify-content: center;
  margin-top: 25px;

  @media (max-width: $w) {
    margin-top: size(25);
  }
}
.popup-melody__form-text{
  font-family: 'adigiana_2regular';
  color: #000;
  font-size: 24px;
  padding-top: 24px;
  text-align: center;

  @media (max-width: $w) {
    font-size: size(24);
    padding-top: size(24);
  }

  a{
    text-decoration: underline;
    color: #000;

    &:hover{
      text-decoration: none;
    }
  }
}

.popup-quiz{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @media (max-width: $w) {
    padding-top: size(50);
  }
}

.popup-quiz.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.popup-quiz__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #ea5ee4;
  width: 100%;
  text-align: center;
  transform: rotate(-10deg);
  margin-top: 60px;
  margin-left: -15px;
  font-size: 194px;
  line-height: 91px;
  margin-bottom: 0;

  @media (max-width: $w) {
    margin-left: size(-15);
    margin-top: size(60);
    font-size: size(194);
    line-height: size(91);
  }
  @media (max-width: $mobile) {
    font-size: sizem(194);
    line-height: sizem(91);
    margin-top: sizem(105);
  }

  span{
    display: block;
  }
}
.popup-quiz__text{
  font-family: 'adigiana_2regular';
  font-size: 32px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;

  @media (max-width: $w) {
    font-size: size(32);
    margin-top: size(30);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    line-height: 0.9;
    margin-top: sizem(30);
  }
  & > div{
    margin-top: 35px;
    width: 62%;
    @media (max-width: $w) {
      margin-top: size(35);
    }
    &:nth-child(1){
      padding-left: 3%;
      transform: rotate(-5deg);
      @media (max-width: $mobile) {
        padding-left: 34%;
        height: sizem(215);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 57%;
      }
    }
    &:nth-child(2){
      @media (max-width: $mobile) {
        transform: rotate(5deg);
        padding-left: 10%;
        margin-top: sizem(235);
        width: 70%;
      }
    }
    &:nth-child(3){
      transform: rotate(3deg);

      @media (max-width: $mobile) {
        padding-left: 4.8%;
        width: 80%;
        margin-top: sizem(35);
      }
    }
  }
  &.popup-quiz__text_bottom{
    margin-top: 0;
    & > div{
      width: 45%;
      margin-top: 0;
      position: relative;
      left: 12%;
      @media (max-width: $mobile) {
        width: 80%;
        left: 0;
        padding-left: 0;
        height: auto;
        padding-bottom: sizem(70);
      }
    }
  }
}

.element-popup-quiz{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    transform: rotate(10deg);
    img{
      width: 100%;
    }
  }
}

.button-in-quiz{
  width: 100%;
  height: 82%;
  position: absolute;
  transform: rotate(-5deg);
  left: -3%;
  top: 0%;
  cursor: pointer;
}

.popup-quiz__button-wrapper{
  text-align: center;
  padding-top: 40px;
  animation: 1s anim6 infinite;
  padding-left: 8%;
  display: none;

  @media (max-width: $w) {
    padding-top: size(40);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(20);
    padding-left: 0;
  }
  &.active{
    display: block;
  }
}

.popup-quiz__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
}

.popup-quiz.active .popup-quiz__in{
  transform: scale(1);
}

.popup-quiz__in .popup__close{
  right: 24px;
  top: -60px;
  padding: 60px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }

  img{
    width: 100%;
  }
}

.popup-quiz__bgs{
  width: 983px;
  height: 1055px;
  position: relative;

  @media (max-width: $w) {
    width: size(983);
    height: size(1055);
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 300ms opacity, 300ms visibility;

    &.active{
      opacity: 1;
      visibility: visible;
    }
  }
}
.popup-quiz__wrap{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.popup-quiz__form{
  display: none;
  &.active{
    display: block;
  }

  input{
    font-family: 'adigiana_2regular';
    color: #000;
    padding: 0;
    border: none;
    z-index: 7;
    display: flex;
    background: url('/assets/img/select_small.png') 50% 50% no-repeat !important;
    background-size: contain;
    align-items: center;
    box-sizing: border-box;
    width: 301px;
    height: 70px;
    font-size: 24px;
    padding: 0 33px;
    outline: none;
    margin-right: 19px;

    @media (max-width: $w) {
      width: size(301);
      height: size(70);
      font-size: size(24);
      padding: 0 size(33);
      margin-right: size(19);
    }

    &::placeholder {
      color: #000;
    }
  }

  .MuiInputBase-root{
    width: 291px;
    background-image: url('/assets/img/select_small2.png');
    @media (max-width: $w) {
      width: size(291);
    }
    .MuiSelect-select.MuiInputBase-input{
      font-size: 24px;

      @media (max-width: $w) {
        font-size: size(24);
      }
    }
  }
  .popup__select{
    margin-bottom: 0;
  }
  .popup__select__icon{
    background-image: url('/assets/img/down_red.png');
    background-size: contain;
    @media (max-width: $w) {
      right: size(17);
      margin-top: size(-16);
      width: size(34);
      height: size(32);
    }
  }

  .popup-quiz__button-wrapper{
    padding-top: 60px;
    @media (max-width: $w) {
      padding-top: size(60);
    }
  }
}
.popup-quiz__form-line{
  display: flex;
  justify-content: center;
  margin-top: 25px;

  @media (max-width: $w) {
    margin-top: size(25);
  }
}
.popup-quiz__form-text{
  font-family: 'adigiana_2regular';
  color: #000;
  font-size: 24px;
  padding-top: 24px;
  text-align: center;

  @media (max-width: $w) {
    font-size: size(24);
    padding-top: size(24);
  }

  a{
    text-decoration: underline;
    color: #000;

    &:hover{
      text-decoration: none;
    }
  }
}


.cookie{
  position: fixed;
  z-index: 100;
  left: 50%;
  bottom: 22px;
  width: 1657px;
  margin-left: 22px;
  transition: 300ms opacity, 300ms visibility;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateX(-50%) scale(0.9);

  @media (max-width: $w) {
    width: size(1657);
    margin-left: size(22);
    bottom: size(22);
  }

  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateX(-50%) scale(1);
  }
}
.cookie__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
}

.cookie.active .cookie__in{
  transform: scale(1);
}
.cookie__bg{
  display: block;
  width: 100%;
}
.cookie__text{
  position: absolute;
  left: 10%;
  top: 12%;
  height: 67%;
  display: flex;
  align-items: center;
  font-family: 'adigiana_2regular';
  font-size: 36px;
  line-height: 1;
  color: #000;

  @media (max-width: $w) {
    font-size: size(36);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
    text-align: center;
    padding: 0 14% 0 0%;
    height: 47%;
    br{
      display: none;
    }
  }

  a{
    color: #000;
  }
}
.element-cookie{
  left: 73.5%;
  top: 27.5%;
  animation: 1s anim6 infinite;

  width: 306px;
  @media (max-width: $w) {
    width: size(306);
  }
  @media (max-width: $mobile) {
    width: sizem(430);
    left: 20.5%;
    top: 52.5%;
  }
}
.button-cookie{
  left: -15%;
  top: -10%;
  width: 120%;
  height: 95%;
  cursor: pointer;
}

.topmusic{
  position: fixed;
  top: -89px;
  left: 50%;
  width: 397px;
  height: 383px;
  z-index: 4;
  transform: translateX(-50%);
  transition: 300ms opacity, 300ms visibility, 300ms transform;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateX(-50%) scale(0.9);

  @media (max-width: $w) {
    top: size(-89);
    width: size(397);
    height: size(383);
  }

  img{
    display: block;
    position: absolute;
  }
  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateX(-50%) scale(1);
  }
  &:hover{
    transform: translateX(-50%) scale(1.05);
  }
}
.topmusic__circle{
  width: 322px;
  z-index: 0;
  left: 12.54%;
  bottom: 15.67%;

  @media (max-width: $w) {
    width: size(322);
  }
}
.topmusic__arrow{
  width: 119px;
  z-index: 5;
  left: 70.3%;
  bottom: 49.9%;

  @media (max-width: $w) {
    width: size(119);
  }
}
.topmusic__note1{
  width: 179px;
  z-index: 2;
  left: 0;
  bottom: 0;
  animation: 1s anim1 infinite;

  @media (max-width: $w) {
    width: size(179);
  }
}
.topmusic__note2{
  width: 118px;
  z-index: 3;
  left: 25.9%;
  bottom: 21.67%;
  animation: 1s 0.2s anim1 infinite;

  @media (max-width: $w) {
    width: size(118);
  }
}
.topmusic__note3{
  width: 97px;
  z-index: 4;
  left: 40.3%;
  bottom: 39.82%;
  animation: 1s 0.4s anim1 infinite;

  @media (max-width: $w) {
    width: size(97);
  }
}


.popup-game{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 100px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @media (max-width: $w) {
    padding-top: size(100);
  }
  &.active{
    .popup-game__wrapin{
      &.active{
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        @media (max-width: $mobile) {
          display: block;
        }
      }
    }
  }
}

.popup-game.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.popup-game__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
  @media (max-width: $mobile) {
    padding-bottom: 0 !important;
  }
}

.popup-game.active .popup-game__in{
  transform: scale(1);
}

.popup-game__in .popup__close{
  right: 54px;
  top: -30px;
  padding: 30px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(54);
    top: size(-30);
    padding: size(30);
  }
  @media (max-width: $mobile) {
    width: sizem(52);
    right: sizem(94) !important;
    top: sizem(100) !important;
    padding: sizem(30);

    &.start{
      right: sizem(40) !important;
      top: sizem(85) !important;
    }
  }

  img{
    width: 100%;
  }
}
.popup-game__title{
  font-family: 'adigiana_2regular';
  position: absolute;
  top: -73px;
  left: 0;
  z-index: 4;
  color: #8851c9;
  width: 100%;
  text-align: center;
  transform: rotate(-10deg);
  font-size: 114px;
  line-height: 52px;
  padding-right: 10%;
  box-sizing: border-box;

  @media (max-width: $w) {
    font-size: size(114);
    line-height: size(52);
    top: size(-73);
  }
  @media (max-width: $mobile) {
    font-size: sizem(114);
    line-height: sizem(52);
    top: sizem(170);
  }

  span{
    display: block;
    &:nth-child(2){
      padding-left: 5%;
    }
    i{
      font-style: normal;
      font-size: 0.63em;
    }
  }
}
.popup-game__wrap{
  width: 983px;
  height: 1126px;
  position: relative;

  @media (max-width: $w) {
    width: size(983);
    height: size(1126);
  }
  @media (max-width: $mobile) {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    &.start{
      height: 585vw;
    }
  }
}
.popup-game__wrapin{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 300ms opacity, 300ms visibility;
  @media (max-width: $mobile) {
    display: none;
  }
  &.start{
    .popup-game__title{
      margin-top: -10px;
      @media (max-width: $w) {
        margin-top: size(-10);
      }
      @media (max-width: $mobile) {
        margin-top: sizem(-40);
        position: relative;
        &::before{
          content: '';
          position: absolute;
          left: -25%;
          top: -200%;
          z-index: -1;
          width: 150%;
          height: 340%;
          background: #efefef;
        }
      }
    }
  }
}
.popup-game__startlayer{
  position: absolute;
  left: -50vw;
  top: -50vh;
  width: 200vw;
  height: 200vh;
  z-index: 10;
  cursor: pointer;
  @media (max-width: $mobile){
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.popup-game__start{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
  padding-top: 12%;
  color: #fff;
  font-family: 'adigiana_2regular';
  font-size: 24px;
  line-height: 1;

  @media (max-width: $w) {
    font-size: size(24);
  }
  @media (max-width: $mobile) {
    height: auto;
    min-height: 100%;
    font-size: sizem(48);
    padding-top: sizem(380);
  }
}
.popup-game__start-title{
  text-align: center;
  transform: rotate(-5deg);
  padding-bottom: 2%;
  span{
    display: block;
    font-size: 3.56em;
    margin-top: -23px;

    @media (max-width: $mobile) {
      font-size: 2.53em;
      padding-bottom: 2%;
    }
  }
  @media (max-width: $w) {
    span{
      margin-top: size(-23);
    }
  }
  @media (max-width: $mobile) {
    transform: rotate(-11deg);
    font-size: sizem(36);
  }
}
.popup-game__start-content{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 12%;
  @media (max-width: $mobile) {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
  & > div{
    position: relative;
    text-align: center;
    box-sizing: border-box;
    @media (max-width: $mobile) {
      margin-top: sizem(50);
    }
    .shadow{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 0;
    }
    .item{
      position: absolute;
      z-index: 1;
    }
    .title{
      position: relative;
      z-index: 1;
      font-size: 1.356em;
      line-height: 0.47;
      transform: rotate(-7deg);
      margin-bottom: 10px;
      @media (max-width: $w) {
        margin-bottom: size(10);
      }
      @media (max-width: $mobile) {
        margin-bottom: sizem(20);
        font-size: sizem(76);
        transform: rotate(-3deg);
      }
    }
    .text{
      position: relative;
      z-index: 1;
      line-height: 0.65;
      transform: rotate(-7deg);
      @media (max-width: $mobile) {
        font-size: sizem(40);
        line-height: 0.9;
        transform: rotate(-3deg);
      }
    }
    .num{
      position: absolute;
      z-index: 1;
      font-size: 2em;
      transform: rotate(-5deg);
    }
  }
}
.popup-game__start-i1{
  width: 324px;
  height: 230px;
  padding-top: 19%;
  padding-left: 6%;

  @media (max-width: $w) {
    width: size(324);
    height: size(230);
  }
  @media (max-width: $mobile) {
    padding-top: 36%;
    width: sizem(685);
    height: sizem(500);
    margin-top: 0 !important;
  }
  .item{
    left: 12%;
    top: 4%;
    width: 226px;

    @media (max-width: $w) {
      width: size(226);
    }
    @media (max-width: $mobile) {
      left: 7%;
      top: 14%;
      width: sizem(561);
    }
  }
}
.popup-game__start-i2{
  width: 272px;
  height: 255px;
  padding-top: 20%;

  @media (max-width: $w) {
    width: size(272);
    height: size(255);
  }
  @media (max-width: $mobile) {
    padding-top: 51%;
    width: sizem(674);
    height: sizem(662);
  }
  .item{
    left: 20%;
    top: 2%;
    width: 117px;

    @media (max-width: $w) {
      width: size(117);
    }
    @media (max-width: $mobile) {
      width: sizem(261);
      left: 28.5%;
      top: 8%;
    }
  }
  .title{
    @media (max-width: $mobile) {
      font-size: sizem(88) !important;
    }
  }
  .text{
    @media (max-width: $mobile) {
      font-size: sizem(43) !important;
    }
  }
}
.popup-game__start-i3{
  width: 246px;
  height: 141px;
  padding-top: 9%;

  @media (max-width: $w) {
    width: size(246);
    height: size(141);
  }
  @media (max-width: $mobile) {
    padding-top: 32%;
    width: sizem(686);
    height: sizem(577);
  }
  .item{
    left: 43%;
    top: 17%;
    width: 39px;

    @media (max-width: $w) {
      width: size(39);
    }
    @media (max-width: $mobile) {
      width: sizem(126);
    }
  }
  .text{
    @media (max-width: $mobile) {
      font-size: sizem(48) !important;
    }
  }
}
.popup-game__start-i4{
  width: 242px;
  height: 163px;
  padding-top: 11%;
  margin-left: 40px;
  margin-top: 10px;

  @media (max-width: $w) {
    width: size(242);
    height: size(163);
    margin-left: size(40);
    margin-top: size(10);
  }
  @media (max-width: $mobile) {
    padding-top: 46%;
    width: sizem(576);
    height: sizem(656);
  }
  .item{
    left: 41%;
    top: 0%;
    width: 28px;

    @media (max-width: $w) {
      width: size(28);
    }
    @media (max-width: $mobile) {
      width: sizem(90);
      transform: rotate(67deg);
      left: 45%;
      top: 16%;
    }
  }
  .text{
    @media (max-width: $mobile) {
      font-size: sizem(48) !important;
    }
  }
}
.popup-game__start-i5{
  width: 284px;
  height: 159px;
  padding-top: 10%;
  margin-top: -20px;

  @media (max-width: $w) {
    width: size(284);
    height: size(159);
    margin-top: size(-20);
  }
  @media (max-width: $mobile) {
    padding-top: 32%;
    width: sizem(684);
    height: sizem(469);
  }
  .item{
    left: 33%;
    top: 8%;
    width: 53px;

    @media (max-width: $w) {
      width: size(53);
    }
    @media (max-width: $mobile) {
      top: 10%;
      width: sizem(156);
    }
  }
  .num{
    left: 38%;
    top: 19%;
    @media (max-width: $mobile) {
      font-size: sizem(138) !important;
    }
  }
  .title{
    @media (max-width: $mobile) {
      font-size: sizem(71) !important;
    }
  }
}
.popup-game__start-i6{
  width: 215px;
  height: 121px;
  padding-top: 8%;
  margin-left: 40px;

  @media (max-width: $w) {
    width: size(215);
    height: size(121);
    margin-left: size(40);
  }
  @media (max-width: $mobile) {
    padding-top: 35%;
    width: sizem(684);
    height: sizem(469);
    margin-left: sizem(40);
  }
  .item{
    left: 27%;
    top: 4%;
    width: 62px;

    @media (max-width: $w) {
      width: size(62);
    }
    @media (max-width: $mobile) {
      width: sizem(175);
      top: 20%;
    }
  }
  .num{
    left: 55.5%;
    top: 4%;
    @media (max-width: $mobile) {
      top: 20%;
      font-size: sizem(130) !important;
    }
  }
  .title{
    @media (max-width: $mobile) {
      font-size: sizem(76) !important;
    }
  }
}

.element-popup-game{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 255px;
  @media (max-width: $w) {
    width: size(255);
  }
  @media (max-width: $mobile) {
    width: sizem(460);
    transform: rotate(14deg);
    img{
      width: 100%;
    }
  }
}

.button-in-game{
  width: 107%;
  height: 92%;
  position: absolute;
  transform: rotate(-5deg);
  left: -5%;
  top: -7%;
  cursor: pointer;
}

.popup-game__button-wrapper{
  text-align: center;
  margin-top: -10px;
  animation: 1s anim6 infinite;
  padding-left: 7%;

  @media (max-width: $w) {
    margin-top: size(-10);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(30);
    padding-left: 0;
  }
}

.popup-game__frame{
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
}
.popup-game__bg{
  display: block;
  position: absolute;
  z-index: 0;
  width: 79.2%;
  left: 10.5%;
  top: 5.2%;
}
.popup-game__circle{
  position: absolute;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #8851c9;
  font-family: 'adigiana_2regular';
  color: #fff;
  font-size: 48px;
  box-sizing: border-box;
  padding-top: 0.8%;

  @media (max-width: $w) {
    width: size(132);
    height: size(132);
    font-size: size(48);
  }
  @media (max-width: $mobile) {
    width: sizem(167);
    height: sizem(167);
    font-size: sizem(48);
  }
  span{
    display: block;
    text-align: center;
  }
  img{
    transition: 300ms transform;
  }
  &.count{
    img{
      transform: scale(1.2);
    }
  }

  &.heart{
    top: 9.5%;
    left: 7.5%;
    @media (max-width: $mobile) {
      top: sizem(-21);
      left: sizem(-30);
    }

    img{
      width: 53px;
      margin-left: -11%;

      @media (max-width: $w) {
        width: size(53);
      }
      @media (max-width: $mobile) {
        width: sizem(67);
      }
    }
    span{
      margin-left: 7%;
    }
  }
  &.snowman{
    top: 4.5%;
    left: 76.5%;
    @media (max-width: $mobile) {
      top: sizem(-21);
      right: sizem(-30);
      left: auto;
    }

    img{
      width: 62px;

      @media (max-width: $w) {
        width: size(62);
      }
      @media (max-width: $mobile) {
        width: sizem(106);
      }
    }
  }
}

.popup-game__game{
  position: absolute;
  z-index: 1;
  width: 77.9%;
  height: 71%;
  left: 11.5%;
  top: 7.2%;
  overflow: hidden;
  border-radius: 11%;
  transform: rotate(-3deg);
  @media (max-width: $mobile) {
    transform: none;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    left: 0%;
    top: 0%;
    border-radius: 0;
  }
  &.bgcolor{
    background: #8851c9;
  }

  .snowman{
    width: 166px;
    height: 177px;
    position: absolute;
    z-index: 0;
    transition: 0.1s top linear,300ms opacity,300ms transform;
    transform: rotate(0deg) scale(1);

    @media (max-width: $w) {
      width: size(166);
      height: size(177);
    }
    @media (max-width: $mobile) {
      width: sizem(188);
      height: sizem(201);
    }

    &::before{
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url('/assets/img/snowman.png') center no-repeat;
      background-size: contain;
    }
    &::after{
      content: '';
      position: absolute;
      z-index: 0;
      left: 21%;
      top: 67%;
      width: 78.31%;
      height: 61.58%;
      background: url('/assets/img/shadow.png') center no-repeat;
      background-size: contain;
    }
    span{
      display: block;
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .left{
      width: 12.88%;
      height: 21.47%;
      left: 37%;
      top: 84%;
      background-image: url('/assets/img/leftleg.png');
      animation: 0.5s anim16 infinite ease-in-out;
    }
    .right{
      width: 16.265%;
      height: 20.339%;
      left: 69%;
      top: 89%;
      background-image: url('/assets/img/rightleg.png');
      animation: 0.5s anim17 infinite ease-in-out;
    }

    &.hit{
      span{
        display: none;
      }
      &::before{
        content: '';
        position: absolute;
        z-index: 2;
        left: -16%;
        top: 30%;
        background: url('/assets/img/ouch.png') center no-repeat;
        background-size: contain;
        width: 290px;
        height: 206px;

        @media (max-width: $w) {
          width: size(290);
          height: size(206);
        }
        @media (max-width: $mobile) {
          width: sizem(290);
          height: sizem(206);
        }
      }
      &::after{
        content: '';
        position: absolute;
        z-index: 0;
        left: 11%;
        top: -36.5%;
        background: url('/assets/img/snowman_hit.png') center no-repeat;
        background-size: contain;
        width: 192px;
        height: 282px;

        @media (max-width: $w) {
          width: size(192);
          height: size(282);
        }
        @media (max-width: $mobile) {
          width: sizem(192);
          height: sizem(282);
        }
      }
    }
    &.dead{
      transition-delay: 0.7s;
      opacity: 0;
      span{
        display: none;
      }
      &::before{
        display: none;
      }
      &::after{
        left: 0%;
        top: 45%;
        background: url('/assets/img/snowman_dead.png') center no-repeat;
        background-size: contain;
        width: 198px;
        height: 107px;

        @media (max-width: $w) {
          width: size(198);
          height: size(107);
        }
        @media (max-width: $mobile) {
          width: sizem(198);
          height: sizem(107);
        }
      }
    }
    &.end{
      transform: rotate(7deg) scale(0.9);
      opacity: 0;
    }
    &.win{
      transition-delay: 0s;
      width: 395px;
      height: 546px;
      left: 30% !important;
      bottom: -80% !important;
      transform: translateY(-107.5%) rotate(7deg) scale(1);
      top: auto !important;
      z-index: 4;
      opacity: 1;

      @media (max-width: $w) {
        width: size(395);
        height: size(546);
      }
      @media (max-width: $mobile) {
        width: sizem(345);
        height: sizem(546);
      }
      .left{
        z-index: 0;
        width: 55.44%;
        height: 42.49%;
        left: -35%;
        top: 12%;
        background-image: url('/assets/img/leftarm.png');
        transform-origin: right;
        animation: 1s anim14 infinite ease-in-out;
      }
      .right{
        z-index: 0;
        width: 65.06%;
        height: 42.49%;
        left: 53%;
        top: -2%;
        background-image: url('/assets/img/rightarm.png');
        transform-origin: left;
        animation: 1s 0.3s anim14 infinite ease-in-out;
      }
      &::before{
        display: none;
      }
      &::after{
        z-index: 1;
        left: 0;
        top: 0;
        background: url('/assets/img/snowman_win.png') center no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .carrot{
    display: block;
    position: absolute;
    z-index: 1;
    width: 34px;
    height: 93px;
    background: url('/assets/img/carrot.png') center no-repeat;
    background-size: contain;
    transition: 0.1s top linear, 500ms transform;

    @media (max-width: $w) {
      width: size(34);
      height: size(93);
    }
    @media (max-width: $mobile) {
      width: sizem(64);
      height: sizem(174);
    }
    &.hit{
      transform: translate(-150px,-300px) scale(0) rotate(-1turn);
      @media (max-width: $w) {
        transform: translate(size(-150),size(-300)) scale(0) rotate(-1turn);
      }
      @media (max-width: $mobile) {
        transform: translate(sizem(-150),sizem(-320)) scale(0) rotate(-1turn);
      }
    }
  }

  .snowball{
    display: block;
    position: absolute;
    z-index: 1;
    width: 44px;
    height: 113px;
    margin-left: -22px;
    background: url('/assets/img/snowball.png') center no-repeat;
    background-size: contain;
    transition: 0.1s bottom linear;
    //animation: 2s anim18;

    @media (max-width: $w) {
      width: size(44);
      height: size(113);
      margin-left: size(-22);
    }
    @media (max-width: $mobile) {
      width: sizem(72);
      height: sizem(185);
      margin-left: sizem(-36);
    }
    &.hit{
      display: none !important;
    }
  }
}

.popup-game__remotecontrol{
  width: 247px;
  position: absolute;
  z-index: 4;
  bottom: -7%;
  left: 36%;

  @media (max-width: $w) {
    width: size(247);
  }
  @media (max-width: $mobile) {
    width: sizem(565);
    bottom: -7%;
    left: 13%;
  }

  &.froze{
    display: none;
  }
}
.popup-game__rules{
  width: 444px;
  position: absolute;
  z-index: 5;
  bottom: 22.3%;
  left: 50.2%;
  transition: 300ms opacity, 300ms visibility, 300ms transform;
  transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @media (max-width: $w) {
    width: size(444);
  }

  &.active{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}
.popup-game.active .popup-game__rules.active{
  pointer-events: auto;
}

.popup-recipe__imgtop{
  @media (max-width: $mobile) {
    position: absolute;
    top: 0;
    left: 0;
    width: sizem(299);
    img{
      width: 100%;
      display: block;
    }
    &.hide{
      display: none !important;
    }
  }
}
.popup-recipe__imgtop2{
  @media (max-width: $mobile) {
    position: absolute;
    top: 0;
    left: 0;
    width: sizem(245);
    z-index: 0;
    img{
      width: 100%;
      display: block;
    }
    &.hide{
      display: none !important;
    }
  }
}
.popup-recipe__imgbottom{
  @media (max-width: $mobile) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: sizem(514);
    img{
      width: 100%;
      display: block;
    }
    &.hide{
      display: none !important;
    }
  }
}
.popup-recipe__imgbottom2{
  @media (max-width: $mobile) {
    position: absolute;
    right: 0;
    width: sizem(228);
    top: sizem(535);
    z-index: 0;
    img{
      width: 100%;
      display: block;
    }
    &.hide{
      display: none !important;
    }
  }
}
.popup-recipe-start__title{
  font-family: 'adigiana_2regular';
  position: relative;
  z-index: 2;
  color: #82b80f;
  width: 100%;
  text-align: center;
  left: -20px;
  transform: rotate(-8deg);
  top: -9px;
  font-size: 87px;
  line-height: 51px;
  margin-bottom: 0;

  @media (max-width: $w) {
    top: size(-9);
    font-size: size(87);
    line-height: size(51);
  }
  @media (max-width: $mobile) {
    top: sizem(230);
    font-size: sizem(87);
    line-height: sizem(51);
    left: 0;
  }
  span{
    display: block;
    &:first-child{
      font-size: 48px;
      line-height: 29px;
      @media (max-width: $w) {
        font-size: size(48);
        line-height: size(29);
      }
      @media (max-width: $mobile) {
        top: sizem(48);
        font-size: sizem(87);
        line-height: sizem(51);
      }
    }
  }
}
.popup-recipe-start__text{
  font-family: 'adigiana_2regular';
  font-size: 30px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  margin-top: -25px;
  padding-left: 22%;

  @media (max-width: $w) {
    font-size: size(30);
    margin-top: size(-25);
  }
  @media (max-width: $mobile) {
    font-size: sizem(40);
    line-height: 0.9;
    margin-top: sizem(65);
    padding-left: 0%;
  }
  & > div{
    margin-top: 50px;
    transform: rotate(-7deg);
    width: 54%;
    @media (max-width: $w) {
      margin-top: size(50);
    }
    @media (max-width: $mobile) {
      width: 70%;
      text-align: center;
      margin-top: sizem(220);
    }
  }
  a{
    text-decoration: underline;
    color: #82b80f;
  }
}
.popup-recipe{
  @media (max-width: $mobile) {
    .popup-wrap__in{
      height: 100%;
    }
    .MuiInputBase-root{
      width: sizem(600);
      .MuiSelect-select.MuiInputBase-input{
        height: sizem(110);
        font-size: sizem(50);
        padding: 0 sizem(50);
      }
    }
  }
}
.element-popup-recipe{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 304px;
  @media (max-width: $w) {
    width: size(304);
  }
  @media (max-width: $mobile) {
    width: sizem(650);
    transform: rotate(-5deg);
    img{
      width: 100%;
    }
  }
}

.button-in-recipe{
  width: 107%;
  height: 92%;
  position: absolute;
  transform: rotate(5deg);
  left: -5%;
  top: -7%;
  cursor: pointer;
}

.popup-recipe__button-wrapper{
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 65px;
  //animation: 1s anim6 infinite;
  padding-left: 1%;

  @media (max-width: $w) {
    padding-top: size(65);
  }
  @media (max-width: $mobile) {
    padding-top: sizem(65);
    position: relative;
    z-index: 2;
    flex-direction: column;
  }
  .popup-recipe__button{
    margin-left: 3%;
    &:first-child{
      animation: 1s anim6 infinite;
    }
    &:last-child{
      animation: 1s anim6 infinite;
    }
  }
}
.cursor-in,.slick-arrow{
  cursor: pointer !important;
}


.popup-shop{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: auto;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @media (max-width: $w) {
    padding-top: size(50);
  }
}

.popup-shop.active{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.popup-shop__title{
  position: relative;
  z-index: 2;
  width: 470px;
  margin: 22px auto 0;
  left: 10px;

  @media (max-width: $w) {
    width: size(470);
    margin-top: size(22);
    left: size(10);
  }
  @media (max-width: $mobile) {
    width: sizem(470);
    margin-top: sizem(22);
    left: sizem(10);
  }

  span{
    display: block;
  }
}
.popup-shop__text{
  font-family: 'Frederik';
  font-weight: bold;
  font-size: 24px;
  line-height: 1.25;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  margin-top: -52px;

  @media (max-width: $w) {
    font-size: size(24);
    margin-top: size(-52);
  }
  @media (max-width: $mobile) {
    font-size: sizem(36);
    margin-top: sizem(-52);
  }
  & > div{
    margin-top: 21px;
    @media (max-width: $w) {
      margin-top: size(21);
    }
    @media (max-width: $mobile) {
      text-align: center;
    }
    &:nth-child(1){
      padding-left: 21%;
      transform: rotate(-7deg);
      width: 40%;
      margin-top: 0;
      @media (max-width: $mobile) {
        width: 80%;
        padding-left: 0;
        margin: sizem(50) auto 0;
      }
    }
    &:nth-child(2){
      padding-left: 33%;
      width: 40%;
      @media (max-width: $mobile) {
        width: 80%;
        padding-left: 0;
        margin: sizem(70) auto 0;
      }
    }
    &:nth-child(3){
      transform: rotate(2deg);
      padding-left: 25%;
      @media (max-width: $mobile) {
        width: 60%;
        padding-left: 0;
        margin: sizem(70) auto 0;
      }
    }
    &.red{
      color: #ed251d;
    }
  }
}

.element-popup-shop{
  position: relative;
  margin: 0 auto;
  display: inline-block;

  width: 295px;
  @media (max-width: $w) {
    width: size(295);
  }
  @media (max-width: $mobile) {
    width: sizem(400);
    margin: sizem(70) auto 0;
  }
}

.button-in-shop{
  width: 100%;
  height: 82%;
  position: absolute;
  transform: rotate(5deg);
  left: -3%;
  top: -6%;
  cursor: pointer;
}

.popup-shop__button-wrapper{
  text-align: center;
  padding-top: 40px;
  animation: 1s anim6 infinite;
  padding-left: 2%;

  @media (max-width: $w) {
    padding-top: size(40);
  }
  &.active{
    display: block;
  }
}

.popup-shop__in{
  position: relative;
  transition: 300ms transform;
  transform: scale(0.9);
}

.popup-shop.active .popup-shop__in{
  transform: scale(1);
}

.popup-shop__in .popup__close{
  right: 24px;
  top: -60px;
  padding: 60px;

  width: 52px;
  @media (max-width: $w) {
    width: size(52);
    right: size(24);
    top: size(-60);
    padding: size(60);
  }

  img{
    width: 100%;
  }
}

.popup-shop__bg{
  width: 1037px;

  @media (max-width: $w) {
    width: size(1037);
  }
}
.popup-shop__wrap{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.popup-window{
  @media (max-width: $mobile) {
    padding: 0 !important;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .popup__close{
      width: sizem(52);
      height: sizem(52);
      padding: sizem(25);
      top: sizem(60);
    }
  }
}
.popup-window__in{
  @media (max-width: $mobile) {
    width: 100vw;
    background: url('/assets/img/mob/popup.png') center top repeat-y;
    background-size: 100% auto;
    padding-bottom: sizem(150);
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.MuiInputBase-root{
  @media (max-width: $mobile) {
    width: sizem(511);
  }
  .MuiSelect-select.MuiInputBase-input{
    @media (max-width: $mobile) {
      height: sizem(117);
      font-size: sizem(40);
      padding: 0 sizem(60) 0 sizem(60);
    }
  }
}
.popup__select{
  @media (max-width: $mobile) {
    margin-top: sizem(20);
  }
}
.popup__select__icon{
  @media (max-width: $mobile) {
    right: sizem(40);
    margin-top: sizem(-24);
    width: sizem(49);
    height: sizem(47);
  }
}

.popup-game__buttons{
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: sizem(25);
  width: 100%;
  @media (max-width: $mobile) {
    justify-content: center;
    display: none !important;
    &.active{
      display: flex !important;
    }
  }
  img:active{
    transform: scale(1,0.9);
  }
}
.popup-game__leftbutton{
  width: sizem(181);
  margin-right: sizem(23);
}
.popup-game__firebutton{
  width: sizem(298);
}
.popup-game__rightbutton{
  width: sizem(181);
  margin-left: sizem(18);
}

.loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


.bird{
  position: absolute;
  left: 11.95%;
  top: 42.55%;
  width: 553px;
  height: 488px;
  @media (max-width: $w) {
    width: size(449);
    height: size(459);
  }
  @media (max-width: $mobile) {
    display: none;
  }
  img{
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    &.active{
      opacity: 1;
    }
  }
}
.bird__i1{
  width: 553px;
  height: 488px;
  @media (max-width: $w) {
    width: size(553);
    height: size(488);
  }
}
.bird__i2{
  width: 582px;
  height: 579px;
  @media (max-width: $w) {
    width: size(582);
    height: size(579);
  }
}
.bird__i3{
  width: 603px;
  height: 581px;
  @media (max-width: $w) {
    width: size(603);
    height: size(581);
  }
}
.box{
  position: absolute;
  left: 62.1%;
  top: 44.25%;
  width: 449px;
  height: 459px;
  @media (max-width: $w) {
    width: size(449);
    height: size(459);
  }
  @media (max-width: $mobile) {
    display: none;
  }
  img{
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    &.active{
      opacity: 1;
    }
  }
}
.box__i1{
  width: 449px;
  height: 459px;
  @media (max-width: $w) {
    width: size(449);
    height: size(459);
  }
}
.box__i2{
  width: 449px;
  height: 513px;
  @media (max-width: $w) {
    width: size(449);
    height: size(513);
  }
}
.box__i3{
  width: 492px;
  height: 559px;
  @media (max-width: $w) {
    width: size(492);
    height: size(559);
  }
}
.boot{
  position: absolute;
  left: 77.6%;
  top: 47.5%;
  width: 428px;
  height: 444px;
  @media (max-width: $w) {
    width: size(428);
    height: size(444);
  }
  @media (max-width: $mobile) {
    display: none;
  }
  img{
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    &.active{
      opacity: 1;
    }
  }
}
.boot__i1{
  width: 428px;
  height: 444px;
  @media (max-width: $w) {
    width: size(428);
    height: size(444);
  }
}
.boot__i2{
  width: 481px;
  height: 656px;
  @media (max-width: $w) {
    width: size(481);
    height: size(656);
  }
}
.boot__i3{
  width: 490px;
  height: 599px;
  @media (max-width: $w) {
    width: size(490);
    height: size(599);
  }
}
.logolink{
  position: absolute;
  left: 31.85%;
  top: 2.62%;
  width: 210px;
  height: 99px;
  @media (max-width: $w) {
    width: size(210);
    height: size(99);
  }
  @media (max-width: $mobile) {
    width: sizem(370);
    height: sizem(125);
    left: 37.1%;
    top: 4.9%;
  }
}

.popup-game__restart{
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 15%;
  box-sizing: border-box;
  transition: 300ms opacity, 300ms visibility;
  opacity: 0;
  visibility: hidden;

  @media (max-width: $mobile) {
    padding-bottom: 0;
    padding-top: 5%;
  }
  span{
    font-family: 'adigiana_2regular';
    font-size: 36px;
    @media (max-width: $w) {
      font-size: size(36);
    }
    @media (max-width: $mobile) {
      font-size: sizem(72);
      display: block;
      padding: 0 sizem(30);
    }
  }
  img{
    width: 308px;
    margin-top: 50px;
    animation: 1s anim6 infinite;
    @media (max-width: $w) {
      width: size(308);
      margin-top: size(50);
    }
    @media (max-width: $mobile) {
      width: sizem(600);
      margin-top: sizem(50);
    }
  }
}

.popup-game.restart{
  cursor: pointer;
  .popup-game__restart{
    opacity: 1;
    visibility: visible;
  }
}

.date{
  position: absolute;
  font-family: 'adigiana_2regular';
  font-size: 48px;
  @media (max-width: $w) {
    font-size: size(48);
  }
  @media (max-width: $mobile) {
    font-size: sizem(55);
  }
}
.date2012{
  left: 37.5%;
  top: 21.5%;
  color: #000;
  transform: rotate(-10deg);

  @media (max-width: $mobile) {
    left: 37%;
    top: 28.5%;
  }
}
.date2312{
  left: 52%;
  top: 25.6%;
  color: #000;
  transform: rotate(3deg);

  @media (max-width: $mobile) {
    left: 45.5%;
    top: 39.6%;
  }
}
.date1301{
  left: 75.7%;
  top: 30.5%;
  color: #000;
  transform: rotate(2deg);

  @media (max-width: $mobile) {
    left: 65.8%;
    top: 45.5%;
  }
}

.underline{
  text-decoration: underline;
}

a.underline{
  color: #000;
  &:hover{
    text-decoration: none;
  }
}




.popup-hello{
  background: rgba(255, 255, 255, 0.89);
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: 300ms opacity, 300ms visibility;
  overflow: hidden;
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
}

.popup-hello.active{
  opacity: 1;
  visibility: visible;
}

.popup-hello__img{
  position: absolute;
  left: 50%;
  margin-left: -150px;
  top: -160px;
  width: 934px;

  @media (max-width: $w) {
    margin-left: size(-150);
    top: size(-160);
    width: size(934);
  }
  @media (max-width: $mobile) {
    margin-left: sizem(-40);
    top: sizem(-160);
    width: sizem(934);
  }
}

.popup-hello__popup{
  position: absolute;
  left: 50%;
  z-index: 2;
  margin-left: -586px;
  top: 176px;
  width: 726px;

  @media (max-width: $w) {
    margin-left: size(-586);
    top: size(176);
    width: size(726);
  }
  &.p2{
    width: 746px;
    top: 120px;
    margin-left: -583px;
    @media (max-width: $w) {
      margin-left: size(-583);
      width: size(746);
      top: size(120);
    }
    @media (max-width: $mobile) {
      margin-left: sizem(-473);
      width: sizem(746);
      top: sizem(120);
    }
  }
}

.popup-hello__element{
  position: absolute;
  z-index: 3;
  left: 50%;
  margin-left: -473px;
  top: 556px;
  width: 285px;

  @media (max-width: $w) {
    margin-left: size(-473);
    top: size(556);
    width: size(285);
  }
  @media (max-width: $mobile) {
    margin-left: sizem(-303);
    top: sizem(556);
    width: sizem(285);
  }
}

.popup-hello__button{
  position: absolute;
  left: 2%;
  top: 0;
  width: 90%;
  height: 68%;
  cursor: pointer;
}

.popup-hello__element2{
  position: absolute;
  z-index: 3;
  left: 50%;
  margin-left: -473px;
  top: 556px;
  width: 285px;

  @media (max-width: $w) {
    margin-left: size(-473);
    top: size(556);
    width: size(285);
  }
  @media (max-width: $mobile) {
    margin-left: sizem(-373);
    top: sizem(556);
    width: sizem(285);
  }
}